// global json info
import React from 'react';
import { WarningOutlined, ExclamationOutlined, InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import helpUsVideo from '../components/Layout/video/e-disability-help.mp4';
import { Alert } from 'antd';

const armenianPattern = [
  {
    pattern: /^[\u0531-\u0556\u0561-\u0587]+-?[\u0531-\u0556\u0561-\u0587]+$/,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
  {
    required: true,
    message: (
      <span>
        <WarningOutlined type="info-circle" /> Պարտադիր լրացման դաշտ
      </span>
    ),
  },
];

const armenianPatternPatronymicName = [
  {
    pattern: /^[\u0531-\u0556\u0561-\u0587]+-?[\u0531-\u0556\u0561-\u0587]+$/,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
  {
    required: false,
    message: (
      <span>
        <WarningOutlined type="info-circle" /> Պարտադիր լրացման դաշտ
      </span>
    ),
  },
];

export const checkTrue = {
  data: [
    {
      id: '1',
      label: 'Այո',
      name: 'check_true',
      value: 1,
    },
    {
      id: '2',
      label: 'Ոչ',
      name: 'check_true',
      value: 2,
    },
  ],
};

export const haveTrue = {
  data: [
    {
      id: '1',
      label1: 'Ունեմ',
      label2: 'Ունի',
      name: 'check_true',
      value: 1,
    },
    {
      id: '2',
      label1: 'Չունեմ',
      label2: 'Չունի',
      name: 'check_true',
      value: 2,
    },
  ],
};

export const publicDeathFormList = {
  data: [
    {
      label: 'Անուն',
      name: 'firstName',
      disabled: false,
      required: true,
      rules: armenianPattern,
      hidden: 'block',
      ariaLabel: 'Անուն',
    },
    {
      label: 'Ազգանուն',
      name: 'lastName',
      disabled: false,
      required: true,
      rules: armenianPattern,
      hidden: 'block',
      ariaLabel: 'Ազգանուն',
    },
    {
      label: 'Հայրանուն',
      name: 'patronymicName',
      disabled: false,
      required: false,
      rules: armenianPatternPatronymicName,
      hidden: 'block',
      ariaLabel: 'Հայրանուն',
    },
    {
      label: 'ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար',
      name: 'ssn',
      disabled: false,
      required: true,
      rules: [{ required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' }],
      hidden: 'block',
      ariaLabel: 'Հանրային ծառայության համարանիշ կամ, չունենալու մասին տեղեկանքի համար',
    },
    {
      label: 'Ծննդյան ամսաթիվ',
      name: 'birthDate',
      disabled: false,
      required: true,
      rules: [{ required: false, message: 'Տեղեկատվությունը սխալ է լրացված' }],
      hidden: 'block',
      ariaLabel: 'Ծննդյան ամսաթիվ՝ դաշտը մուտքագրել հետեվյալ ֆորմատով՝ օր, /, ամիս, /, տարի',
    },
    {
      label: '',
      name: 'gender',
      disabled: false,
      required: true,
      hidden: 'none',
    },
    {
      label: '',
      name: 'region',
      disabled: false,
      required: true,
      hidden: 'none',
    },
    {
      label: '',
      name: 'community',
      disabled: false,
      required: true,
      hidden: 'none',
    },
    {
      label: '',
      name: 'settlement',
      disabled: false,
      required: true,
      hidden: 'none',
    },
    {
      label: '',
      name: 'street',
      disabled: false,
      required: true,
      hidden: 'none',
    },
    {
      label: '',
      name: 'building',
      disabled: false,
      required: true,
      hidden: 'none',
    },
    {
      label: '',
      name: 'buildingType',
      disabled: false,
      required: true,
      hidden: 'none',
    },
    {
      label: '',
      name: 'apartment',
      disabled: false,
      required: true,
      hidden: 'none',
    },
  ],
};

export const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  size: 'large',
  // borderColor: '#0B847F',
};

export const publicFormList = {
  data: [
    {
      label: 'Անուն',
      name: 'firstName',
      disabled: false,
      required: true,
      ariaLabel: 'Անուն',
    },
    {
      label: 'Ազգանուն',
      name: 'lastName',
      disabled: false,
      required: true,
      ariaLabel: 'Ազգանուն',
    },
    {
      label: 'Հայրանուն',
      name: 'patronymicName',
      disabled: false,
      required: false,
      ariaLabel: 'Հայրանուն',
    },
    {
      label: 'ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար',
      name: 'ssn',
      disabled: false,
      required: true,
      ariaLabel: 'Հանրային ծառայության համարանիշ կամ, չունենալու մասին տեղեկանքի համար',
    },
    {
      label: 'Ծննդյան ամսաթիվ',
      name: 'birthDate',
      disabled: false,
      required: true,
      ariaLabel: 'Ծննդյան ամսաթիվ՝ դաշտը մուտքագրել հետեվյալ ֆորմատով՝ օր, /, ամիս, /, տարի',
    },
  ],
};

export const ssnFormList = {
  data: [
    {
      label: 'Հեռախոսի համար',
      name: 'phoneNumber',
      disabled: false,
      required: true,
      ariaLabel: 'Հեռախոսի համար, համարը մուտքգրեք առանց զրոյի',
    },
    {
      label: 'Էլեկտրոնային փոստի հասցե',
      name: 'email',
      disabled: false,
      required: false,
      ariaLabel:
        'Էլեկտրոնային փոստի հասցե, եթե ցանկանում եք ծանուցումները կամ որոշումը ստանալ էլ. փոստով, ապա պարտադիր նշեք էլեկտրոնային հասցե',
    },
  ],
};

export const COLORS = {
  whiteGray: '#555965',
};

export const GENDER_LIST_USER = {
  M: 'Արական',
  F: 'Իգական',
};

export const NUMBERS_USER = {
  ITEMCASEZERO: 0,
  ITEMCASEONE: 1,
  ITEMCASETWO: 2,
  ITEMCASETHREE: 3,
  ITEMCASEFOURE: 4,
  ITEMCASEFIVE: 5,
};

export const requiredMessage = (
  <span>
    <WarningOutlined type="info-circle" /> Պարտադիր լրացման դաշտ
  </span>
);
export const patternMessage = <span>Տեղեկատվությունը սխալ է լրացված</span>;
export const dataReassessmentError = (
  <Alert message={<span style={{ fontWeight: 'bold' }}>Իրավունակ որոշում չի գտնվել </span>} type="warning" showIcon />
);
export const dataInfoReapetError = (
  <span style={{ color: 'red' }}>
    <ExclamationOutlined
      style={{
        fontSize: '22px',
        color: 'red',
      }}
    />{' '}
    Օրինական ներկայացուցչի եւ գնահատվող անձի տվյալները չեն կարող կրկնվել
  </span>
);
export const dataFirstStepError = (
  <p style={{ marginTop: '50px', fontWeight: 'bold' }}>Դիմումի այս տեսակը դեռևս հասանելի չէ</p>
);
export const personStatusTrue = (
  <p style={{ marginTop: '50px', fontWeight: 'bold' }}>
    <Alert message={<span style={{ fontWeight: 'bold' }}>Անձը նույնականացվել է</span>} type="success" showIcon />
  </p>
);
export const uploadImgError = (
  <Alert
    message={<span style={{ fontWeight: 'bold', color: 'red' }}>Փաստաթուղթը չի կցվել</span>}
    type="error"
    showIcon
  />
);
export const personDateFalse =
  '16 տարեկանից ցածր տարիքի դեպքում տվյալ անձի դիմումը պետք է լրացվի նրա օրինական ներկայացուցչի կողմից։';
export const personDateFalseChildrenCase = 'Դիմումատուի տարիքը բարձր է 16-ից։ Խնդրում ենք վերանայել լրացված տվյալները։';
export const personDateApplicantFalse = 'Օրինական ներկայացուցչը չի կարող լինել 16-ից ցածր տարիքի';
export const notSubbmitingData = 'Անհրաժեշտ է գործարկել Որոնել հրամանը';
export const searchErrorFalse = (
  <p style={{ fontWeight: 'bold' }}>
    <p>Խնդրում ենք վերստուգել լրացված տվյալները և կրկին որոնել:</p>
    <p>
      Խնդրում ենք մոտենալ Ձեր բնակության վայրը սպասարկող Միասնական սոցիալական ծառայության (ՄՍԾ) կենտրոն, եթե խնդիրը
      կրկնվում է:
    </p>
  </p>
);
export const helpVideo = (
  <p style={{ padding: '5px 20px', marginBottom: '5px' }}>
    Դժվարանո՞ւմ եք ինքնուրույն լրացնել դիմումը։ Օգտվեք տեսաուղեցույցից՝ սեղմելով{' '}
    <a target="_blank" href={helpUsVideo} style={{ color: '#004D99', textDecoration: 'underline' }} rel="noreferrer">
      այստեղ
    </a>
  </p>
);

export const style = { borderRadius: 10, color: '#000' };

// ApplicantGrid page info

export const optionsListCase = [
  {
    id: 1,
    label: 'Ինձ համար',
    value: 1,
    optionclick: false,
    form_case: false,
    items_select: [],
  },
  {
    id: 2,
    label: 'Որպես դիմումատուի օրինական ներկայացուցիչը',
    value: 2,
    optionclick: false,
    form_case: true,
    items_select: [
      {
        id: 1,
        label: 'Երեխաների դեպքում՝',
        value: 1,
        optionclick: false,
        select_case: [
          { id: 1, label: 'ծնող', value: 1 },
          { id: 2, label: 'որդեգրող', value: 2 },
          { id: 3, label: 'խնամակալ', value: 3 },
          { id: 4, label: 'խնամատար', value: 4 },
          { id: 5, label: 'հոգաբարձու', value: 5 },
        ],
      },
      {
        id: 2,
        label: 'Մեծահասակների դեպքում՝',
        value: 2,
        optionclick: false,
        select_case: [
          { id: 1, label: 'խնամակալ', value: 1 },
          { id: 2, label: 'խնամատար', value: 2 },
          { id: 3, label: 'հոգաբարձու', value: 3 },
          { id: 4, label: 'փաստաբան', value: 4 },
        ],
      },
    ],
  },
];

export const titlesValue1 = [
  // GeneralInfo (page 3)
  'Խնդրում եմ գնահատումն իրականացնել',
  'Ծանուցումը նախընտրում եմ ստանալ',
  // GeneralInfoLast (page 4)
  'Ապրում եք',
  'Ունե՞ք խնամող անձ',
  'Որպես ի՞նչ եք աշխատել',
  // AssessmentQuestionnaire (page 5)
  'Ունե՞ք ինքնասպասարկման դժվարություններ',
  'Ունե՞ք դժվարություններ կենցաղային գործերում',
  'Ունե՞ք տեղաշարժման դժվարություններ',
  'Ունե՞ք դժվարություններ մարմնի դիրք փոխելիս',
  'Ունե՞ք դժվարություններ ձեռքերն օգտագործելիս',
  'Ունե՞ք հաղորդակցման դժվարություններ',
  'Ունե՞ք լսելու հետ կապված դժվարություններ',
  'Ունե՞ք տեսնելու հետ կապված դժվարություններ',
  'Ունե՞ք մարդկանց հետ շփվելու դժվարություններ',
  'Ունե՞ք դժվարություններ տրանսպորտից օգտվելիս',
  'Ծխու՞մ եք',
  // AssessmentQuestionnaireLast (page 6)
  'Օգտագործու՞մ եք լսելու համար նախատեսված օժանդակ սարք',
  'Օգտագործու՞մ եք սայլակ, հենակներ կամ տեղաշարժմանն աջակցող այլ պարագաներ',
  'Ունե՞ք բնակարանը, շենքը հարմարեցնելու անհրաժեշտություն',
  'Ունե՞ք ուղեկցողի աջակցության կամ անձնական խնամք տրամադրողի անհրաժեշտություն',
  'Մարդկանց (ընտանիքի անդամների, ընկերների, ղեկավար անձանց, անձնական օգնականի եւ այլի) վերաբերմունքն ազդու՞մ է Ձեր վարքագծի կամ գործողությունների վրա',
  'Սովորու՞մ եք որեւէ կրթական հաստատությունում',
  'Ամեն օր հաճախու՞մ եք կրթական հաստատություն',
  'Ունե՞ք ուսումնական նյութերի, գույքի, համակարգչային տեխնիկայի հարմարեցման անհրաժեշտություն',
  'Աշխատու՞մ եք',
  'Ունե՞ք աշխատանքային միջավայրի հարմարեցման անհրաժեշտություն',
  'Ունե՞ք խոչընդոտներ աշխատելու համար',
  'Կարիք ունե՞ք մասնագիտական ուսուցման կամ վերաորակավորման դասընթացների',
  'Ֆինանսապես ինքնուրու՞յն եք',
  'Որոշումը նախընտրում եմ ստանալ',
  'Ունե՞ք անչափահաս երեխա(ներ)',
  'Որպես ի՞նչ եք աշխատում',
];

export const providedList = [
  {
    id: 1,
    label: 'Ես հավաստում եմ, որ իմ կողմից տրամադրած տեղեկատվությունը համապատասխանում է իրականությանը',
    value: 1,
  },
];

export const approvedList = [
  {
    id: 1,
    label:
      'Տալիս եմ համաձայնությունս իմ անձնական տվյալները Հայաստանի Հանրապետության Օրենսդրությամբ սահմանված կարգով մշակելու համար',
    value: 1,
  },
];

export const titlesValue2 = [
  // GeneralInfo (page 3)
  'Խնդրում է գնահատումն իրականացնել',
  'Ծանուցումը նախընտրում է ստանալ',
  // GeneralInfoLast (page 4)
  'Ապրում է',
  'Ունի՞ խնամող անձ',
  'Որպես ի՞նչ է աշխատել',
  // AssessmentQuestionnaire (page 5)
  'Ունի՞ ինքնասպասարկման դժվարություններ',
  'Ունի՞ դժվարություններ կենցաղային գործերում',
  'Ունի՞ տեղաշարժման դժվարություններ',
  'Ունի՞ դժվարություններ մարմնի դիրք փոխելիս',
  'Ունի՞ դժվարություններ ձեռքերն օգտագործելիս',
  'Ունի՞ հաղորդակցման դժվարություններ',
  'Ունի՞ լսելու հետ կապված դժվարություններ',
  'Ունի՞ տեսնելու հետ կապված դժվարություններ',
  'Ունի՞ մարդկանց հետ շփվելու դժվարություններ',
  'Ունի՞ դժվարություններ տրանսպորտից օգտվելիս',
  'Ծխու՞մ է',
  // AssessmentQuestionnaireLast (page 6)
  'Օգտագործու՞մ է լսելու համար նախատեսված օժանդակ սարք',
  'Օգտագործու՞մ է սայլակ, հենակներ կամ տեղաշարժմանն աջակցող այլ պարագաներ',
  'Ունի՞ բնակարանը, շենքը հարմարեցնելու անհրաժեշտություն',
  'Ունի՞ ուղեկցողի աջակցության կամ անձնական խնամք տրամադրողի անհրաժեշտություն',
  'Մարդկանց (ընտանիքի անդամների, ընկերների, ղեկավար անձանց, անձնական օգնականի եւ այլի) վերաբերմունքն ազդու՞մ է նրա վարքագծի կամ գործողությունների վրա',
  'Սովորու՞մ է որեւէ կրթական հաստատությունում',
  'Ամեն օր հաճախու՞մ է կրթական հաստատություն',
  'Ունի՞ ուսումնական նյութերի, գույքի, համակարգչային տեխնիկայի հարմարեցման անհրաժեշտություն',
  'Աշխատու՞մ է',
  'Ունի՞ աշխատանքային միջավայրի հարմարեցման անհրաժեշտություն',
  'Ունի՞ խոչընդոտներ աշխատելու համար',
  'Կարիք ունի՞ մասնագիտական ուսուցման կամ վերաորակավորման դասընթացների',
  'Ֆինանսապես ինքնուրու՞յն է',
  'Որոշումը նախընտրում է ստանալ',
  'Ունի՞ անչափահաս երեխա(ներ)',
  'Որպես ի՞նչ է աշխատում',
];

// GeneralInfo page info

export const evaluationList = [
  {
    id: 2,
    label: 'ՄՍԾ կենտրոնում',
    value: 2,
    optionClick: false,
    evalution_select: [],
    ariaLabel: 'Միասնական սոցիալական ծառայության կենտրոնում',
  },
  {
    id: 1,
    label: 'Գտնվելու վայրում',
    value: 1,
    optionClick: false,
    ariaLabel: 'Գտնվելու վայրում',
    evalution_select: [
      {
        id: 1,
        optionClick: false,
        label: 'Բժշկական հաստատությունում',
        items: [],
        ariaLabel: 'Բժշկական հաստատությունում',
      },
      {
        id: 2,
        optionClick: false,
        label: 'Փաստացի գտնվելու վայրում',
        items: [],
        ariaLabel: 'Փաստացի գտնվելու վայրում',
      },
    ],
  },
];

// GeneralInfoLast page info

export const familyStatus = [
  {
    id: 1,
    label1: 'ամուսնացած եմ',
    label2: 'ամուսնացած է',
    IsChild: false,
    value: 1,
  },
  {
    id: 2,
    label1: 'ամուսնացած չեմ',
    label2: 'ամուսնացած չի',
    IsChild: false,
    value: 2,
  },
  {
    id: 3,
    label1: 'այրի եմ',
    label2: 'այրի է',
    IsChild: false,
    value: 3,
  },
  {
    id: 4,
    label1: 'ամուսնալուծված եմ',
    label2: 'ամուսնալուծված է',
    IsChild: false,
    value: 4,
  },
  {
    id: 5,
    label1: 'միայնակ հայր/մայր եմ',
    label2: 'միայնակ հայր/մայր է',
    IsChild: false,
    value: 5,
  },
  {
    id: 6,
    label1: 'երեխա եմ',
    label2: 'երեխա է',
    IsChild: true,
    value: 6,
  },
];

export const livingItems = [
  {
    id: 1,
    label1: 'հոր եւ մոր հետ',
    label2: 'հոր եւ մոր հետ',
    name: 'choose_living',
  },
  {
    id: 2,
    label1: 'որդեգրված եմ',
    label2: 'որդեգրված է',
    name: 'choose_living',
  },
  {
    id: 3,
    label1: 'խնամակալության տակ եմ',
    label2: 'խնամակալության տակ է',
    name: 'choose_living',
  },
  {
    id: 4,
    label1: 'հաստատությունում եմ',
    label2: 'հաստատությունում է',
    name: 'choose_living',
  },
  {
    id: 5,
    label1: 'միայնակ ծնողի հետ',
    label2: 'միայնակ ծնողի հետ',
    name: 'choose_living',
  },
  {
    id: 6,
    label1: 'խնամատար ընտանիքում եմ ապրում',
    label2: 'խնամատար ընտանիքում է ապրում',
    name: 'choose_living',
  },
  {
    id: 7,
    label1: 'ընտանիքիս հետ',
    label2: 'ընտանիքի հետ',
    name: 'choose_living',
  },
  {
    id: 8,
    label1: 'միայնակ',
    label2: 'միայնակ',
    name: 'choose_living',
  },
  {
    id: 9,
    label1: 'այլ',
    label2: 'այլ',
    name: 'choose_living',
  },
];

export const familyComposition = [
  {
    id: 1,
    label: 'ամուսին',
    value: 1,
  },
  {
    id: 2,
    label: 'կին',
    value: 2,
  },
  {
    id: 3,
    label: 'տատիկ',
    value: 3,
  },
  {
    id: 4,
    label: 'քույր',
    value: 4,
  },
  {
    id: 5,
    label: 'եղբայր',
    value: 5,
  },
  {
    id: 6,
    label: 'պապիկ',
    value: 6,
  },
  {
    id: 7,
    label: 'մայր',
    value: 7,
  },
  {
    id: 8,
    label: 'հայր',
    value: 8,
  },
  {
    id: 9,
    label: 'Չունեմ ընտանիքի անդամներ',
    value: 9,
  },
  {
    id: 10,
    label: 'Այլ',
    value: 10,
  },
];

export const caregiverCase = [
  {
    id: 1,
    label: 'Այո',
    name: 'check_true',
    value: 1,
    optionClick: false,
    items: [
      {
        id: 1,
        label: 'ընտանիքի անդամ',
        name: 'choose_employment',
        value: 1,
      },
      {
        id: 2,
        label: 'հարազատ',
        name: 'choose_employment',
        value: 2,
      },
      {
        id: 3,
        label: 'հաստատություն',
        name: 'choose_employment',
        value: 3,
      },
      {
        id: 4,
        label: 'կազմակերպություն',
        name: 'choose_employment',
        value: 4,
      },
      {
        id: 5,
        label: 'վճարովի ծառայություն',
        name: 'choose_employment',
        value: 5,
      },
    ],
  },
  {
    id: 2,
    label: 'Ոչ',
    name: 'check_true',
    value: 2,
    optionClick: false,
    items: [],
  },
];

// AssessmentQuestionnaire page info

export const selfCareDifficulties = [
  {
    id: 1,
    label: 'Այո',
    name: 'check_true',
    value: 1,
    optionClick: false,
    items: [
      {
        id: 1,
        label: 'լվացվելիս',
        value: 1,
      },
      {
        id: 2,
        label: 'լոգանք ընդունելիս',
        value: 2,
      },
      {
        id: 3,
        label: 'խմելիս',
        value: 3,
      },
      {
        id: 4,
        label: 'հագնվելիս',
        value: 4,
      },
    ],
  },
  {
    id: 2,
    label: 'Ոչ',
    name: 'check_true',
    value: 2,
    optionClick: false,
    items: [],
  },
];

export const houseHoldItem = [
  {
    id: 1,
    label: 'Այո',
    name: 'check_true',
    value: 1,
    optionClick: false,
    items: [
      {
        id: 1,
        label: 'տնային մաքրության գործեր անելիս',
        value: 1,
      },
      {
        id: 2,
        label: 'կերակուր պատրաստելիս',
        value: 2,
      },
      {
        id: 3,
        label: 'բակը/հողամասը խնամելիս',
        value: 3,
      },
      {
        id: 4,
        label: 'գնումներ կատարելիս',
        value: 4,
      },
    ],
  },
  {
    id: 2,
    label: 'Ոչ',
    name: 'check_true',
    value: 2,
    optionClick: false,
    items: [],
  },
];

export const movementDifficulties = [
  {
    id: 1,
    label: 'Այո',
    name: 'check_true',
    value: 1,
    optionClick: false,
    items: [
      {
        id: 1,
        label: 'քայլելիս',
        value: 1,
      },
      {
        id: 2,
        label: 'աստիճաններով բարձրանալիս',
        value: 2,
      },
      {
        id: 3,
        label: 'բնակարանում կամ տանը ազատ տեղաշարժվելիս',
        value: 3,
      },
      {
        id: 4,
        label: 'բնակարանից կամ տանից դուրս ազատ տեղաշարժվելիս',
        value: 4,
      },
    ],
  },
  {
    id: 2,
    label: 'Ոչ',
    name: 'check_true',
    value: 2,
    optionClick: false,
    items: [],
  },
];

export const bodyPosition = [
  {
    id: 1,
    label: 'Այո',
    name: 'check_true',
    value: 1,
    optionClick: false,
    items: [
      {
        id: 1,
        label: 'կանգնելիս',
        value: 1,
      },
      {
        id: 2,
        label: 'նստելիս',
        value: 2,
      },
      {
        id: 3,
        label: 'կքանստելիս',
        value: 3,
      },
      {
        id: 4,
        label: 'պառկելիս',
        value: 4,
      },
      {
        id: 5,
        label: 'առանց օգնության նստած դիրքով այլ տեղ տեղափոխվելիս',
        value: 5,
      },
    ],
  },
  {
    id: 2,
    label: 'Ոչ',
    name: 'check_true',
    value: 2,
    optionClick: false,
    items: [],
  },
];

export const communicationDifficulties = [
  {
    id: 1,
    label: 'Այո',
    name: 'check_true',
    value: 1,
    optionClick: false,
    items: [
      {
        id: 1,
        label: 'Ընտանիքի անդամների հետ շփվելիս',
        value: 1,
      },
      {
        id: 2,
        label: 'ընկերների հետ շփվելիս',
        value: 2,
      },
      {
        id: 3,
        label: 'այլ ծանոթների հետ շփվելիս',
        value: 3,
      },
      {
        id: 4,
        label: 'անծանոթների հետ շփվելիս',
        value: 4,
      },
    ],
  },
  {
    id: 2,
    label: 'Ոչ',
    name: 'check_true',
    value: 2,
    optionClick: false,
    items: [],
  },
];

export const useTransportList = [
  {
    id: 1,
    label: 'Այո',
    name: 'check_true',
    value: 1,
    optionClick: false,
    items: [
      {
        id: 1,
        label1: 'խուսափում եմ մարդաշատ վայրերից',
        label2: 'խուսափում է մարդաշատ վայրերից',
        value: 1,
      },
      {
        id: 2,
        label1: 'գումար չունեմ',
        label2: 'գումար չունի',
        value: 2,
      },
      {
        id: 3,
        label1: 'հարմարեցված պայմաններ չկան',
        label2: 'հարմարեցված պայմաններ չկան',
        value: 3,
      },
    ],
  },
  {
    id: 2,
    label: 'Ոչ',
    name: 'check_true',
    value: 2,
    optionClick: false,
    items: [],
  },
];

export const smokeItemList = [
  {
    id: 1,
    label: 'Այո',
    name: 'check_true',
    value: 1,
    optionClick: false,
    items: [],
  },
  {
    id: 2,
    label: 'Ոչ',
    name: 'check_true',
    value: 2,
    optionClick: false,
    items: [
      {
        id: 1,
        label1: 'երբեք չեմ ծխել',
        label2: 'երբեք չի ծխել',
        IsChild: false,
        value: 1,
      },
      {
        id: 2,
        label1: 'թողել եմ անձնական ցանկությամբ',
        label2: 'թողել է անձնական ցանկությամբ',
        IsChild: false,
        value: 2,
      },
      {
        id: 3,
        label1: 'թողել եմ հիվանդության պատճառով',
        label2: 'թողել է հիվանդության պատճառով',
        IsChild: false,
        value: 3,
      },
      {
        id: 4,
        label1: 'երեխա եմ',
        label2: 'երեխա է',
        IsChild: true,
        value: 4,
      },
    ],
  },
];

export const tooltipGrid = (
  <>
    <span style={{ margin: 30, fontSize: 17, marginLeft: 30 }} aria-label={'Ուշադրություն'}>
      {' '}
      <WarningOutlined type="info-circle" style={{ color: '#CC0000' }} /> Ուշադրություն
    </span>
    <div
      style={{
        border: '1px solid black',
        borderRadius: 40,
        padding: 20,
        marginBottom: 20,
        marginLeft: 10,
        color: '#000000',
      }}
    >
      <ul style={{ margin: 0 }}>
        <li
          aria-label={
            'Նույնականացման համար անհրաժեշտ անձնական տվյալները պետք է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան'
          }
        >
          Նույնականացման համար անհրաժեշտ անձնական տվյալները պետք է լրացնել անձը հաստատող փաստաթղթի տվյալներին
          համապատասխան
        </li>
        <li
          aria-label={'տասնվեց տարեկանից ցածր անձանց դեպքում դիմումը պետք է լրացվի անձի օրինական ներկայացուցչի կողմից'}
        >
          16-ից ցածր անձանց դեպքում դիմումը պետք է լրացվի անձի օրինական ներկայացուցչի կողմից
        </li>
        <li
          aria-label={
            'Եթե կա դիմումին կցվող փաստաթուղթ, խնդրում ենք հավաստիանալ վերջինիս համապատասխանության մեջ նախքան դիմումի վերջնական ներկայացումը (կցվող փաստաթղթերը կարող են լինել jpg,jpeg,png,pdf ֆորմատի եւ առավելագույնը տաս մեգաբայթ չափի)'
          }
        >
          Եթե կա դիմումին կցվող փաստաթուղթ, խնդրում ենք հավաստիանալ վերջինիս համապատասխանության մեջ նախքան դիմումի
          վերջնական ներկայացումը (կցվող փաստաթղթերը կարող են լինել jpg/jpeg/png/pdf ֆորմատի եւ առավելագույնը 10 MB չափի)
        </li>
        <li
          aria-label={
            'Եթե հետագա ծանուցումները նախընտրում եք ստանալ էլեկտրոնային փոստով, ապա դիմումում անհրաժեշտ է նշել գործող էլեկտրոնային փոստի հասցե'
          }
        >
          Եթե հետագա ծանուցումները նախընտրում եք ստանալ էլեկտրոնային փոստով, ապա դիմումում անհրաժեշտ է նշել գործող
          էլեկտրոնային փոստի հասցե
        </li>
        <li aria-label={'Հարցերի տեքստային պատասխանները պետք է լրացնել հայատառ'}>
          Հարցերի տեքստային պատասխանները պետք է լրացնել հայատառ
        </li>
        <li aria-label={'Պարտադիր դաշտերը ցուցադրված են աստղանիշ նշանով'}>
          Պարտադիր դաշտերը ցուցադրված են <span style={{ color: 'red' }}>*</span> նշանով
        </li>
      </ul>
    </div>

    <p>
      <InfoCircleOutlined
        type="info-circle"
        aria-label="Դիմումին հաջորդող քայլերի մասին հավելյալ կծանուցվեք՝ ըստ ընտրված ծանուցման եղանակի"
        tooltip="Դիմումին հաջորդող քայլերի մասին հավելյալ կծանուցվեք՝ ըստ ընտրված ծանուցման եղանակի"
        style={{ paddingRight: '8px', fontSize: '18px', color: '#FEBB30', marginLeft: 30 }}
      />
      Դիմումին հաջորդող քայլերի մասին հավելյալ կծանուցվեք՝ ըստ ընտրված ծանուցման եղանակի
    </p>
    <p>
      <InfoCircleOutlined
        type="info-circle"
        aria-label="Առաջացած խնդիրների կամ հարցերի դեպքում մոտենալ բնակության վայրը սպասարկող Միասնական սոցիալական ծառայության կենտրոն"
        tooltip="Առաջացած խնդիրների կամ հարցերի դեպքում մոտենալ բնակության վայրը սպասարկող Միասնական սոցիալական ծառայության (ՄՍԾ) կենտրոն"
        style={{ paddingRight: '8px', fontSize: '18px', color: '#FEBB30', marginLeft: 30 }}
      />
      Առաջացած խնդիրների կամ հարցերի դեպքում մոտենալ բնակության վայրը սպասարկող Միասնական սոցիալական ծառայության (ՄՍԾ)
      կենտրոն
    </p>
  </>
);
