import './css/bvi.css';
import './css/bootstrap.min.css';
import './js/bvi.js';
import jQuery from 'jquery';
import eye from './images/visible.svg';
import { useLocation } from 'react-router-dom';

const secStyle = {
  height: 50,
  width: 50,
  paddingTop: 35,
  position: 'absolute',
  top: '-17px',
  right: '260px',
};

const firstStyle = {
  height: 60,
  width: 60,
  cursor: 'pointer',
  margin: '0 5px 0 5px',
  border: '2px solid #ffffff',
  borderRadius: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
};

export default function VisionServices() {
  const location = useLocation();
  const handleClcik = () => {
    jQuery(document).ready(function ($) {
      $.bvi();
    });
  };

  return (
    <>
      <p className="bvi-open" style={location.pathname === '/home' ? firstStyle : secStyle} onClick={handleClcik}>
        <img src={eye} style={{ hceight: 45, width: 45, cursor: 'pointer' }} width={45} alt="" />
      </p>
    </>
  );
}
