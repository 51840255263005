import React, { useRef, useState, useEffect } from 'react';
import { notification, Col, Form, Radio, Row, Modal, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import EdbRadio from '../../Radio/index';
import { requiredMessage, formItemLayout, tooltipGrid } from '../../../helpers/userapp';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
import { DICTIONARY_URL } from '../../../helpers/constants';

export const FirstGrid = (props) => {
  const form = Form.useFormInstance();
  const [current, setCurrent] = useState(null);
  const { data: listItems } = useGetDictionaryPublic(DICTIONARY_URL.GET_SUBTYPES);
  const ref = useRef(null);
  const firstRadioButtonRef = useRef(null); // Ref for the first radio button in the second group
  const notificationShown = useRef(false);
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(true);

  const openNotificationWithIcon = (type) => {
    api[type]({
      description: 'Ընտրել դիմումի տեսակը',
      icon: (
        <InfoCircleOutlined
          type="info-circle"
          tooltip="Ընտրել այս դաշտերից մեկը"
          style={{ paddingRight: '8px', color: '#FEBB30' }}
        />
      ),
    });
  };

  useEffect(() => {
    if (!notificationShown.current) {
      openNotificationWithIcon('info');
      notificationShown.current = true;
    }
    setIsModalVisible(true);
  }, []);

  useEffect(() => {
    if (current && firstRadioButtonRef.current) {
      // Focus on the first radio button in the second group when the current item is selected
      firstRadioButtonRef.current.focus();
    }
  }, [current]);

  const listClickHandler = (itemId) => {
    listItems.forEach((item) => {
      if (item.id === itemId) {
        setCurrent(item);
      }
    });
    props?.setCountItems(itemId);
    form.setFieldsValue({
      primary_assessment: itemId,
    });
    form.validateFields(['primary_assessment']);
    form.setFieldsValue({
      radio_button_id: null,
    });
  };

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const listClickLastButtonHandler = (value) => {
    props.setReassessment(() => value);
    props.setSubtypeId(() => value);
    form.setFieldsValue({
      radio_button_id: value,
    });
    form.validateFields(['radio_button_id']);
  };

  const handleKeyPressEvent = (e, id) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      listClickHandler(id);
    }
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
    console.log(e);
    if (e.key === 'esc' || e.key === 'Enter') {
      e.preventDefault();
      firstRadioButtonRef.current.focus();
    }
  };

  return (
    <>
      {contextHolder}
      <Form.Item {...formItemLayout} name="primary_assessment" rules={[{ required: true, message: requiredMessage }]}>
        <Radio.Group className="primary_assessment grid_row" style={{ width: '100%' }}>
          <Row gutter={[16, 16]} justify="center">
            {listItems &&
              listItems.map(({ name, id }) => (
                <Col key={id} sm={12} xs={24} md={12} lg={6} className="gutter-row" style={{ border: 'none' }}>
                  <EdbRadio
                    key={id}
                    value={id}
                    onClick={() => listClickHandler(id)}
                    onKeyDown={(e) => handleKeyPressEvent(e, id)}
                  >
                    <Col
                      tabIndex="0"
                      onClick={handleClick}
                      onKeyDown={(e) => handleKeyPressEvent(e, id)}
                      className={current?.id === id ? 'active_bg' : 'accessibility-shadow'}
                      style={{ fontSize: '25px', lineHeight: '35px', width: '271px' }}
                      rules={[{ required: true, message: requiredMessage }]}
                    >
                      {name}
                    </Col>
                  </EdbRadio>
                </Col>
              ))}
          </Row>
        </Radio.Group>
      </Form.Item>

      <div
        style={{
          marginTop: '40px',
          color: '#0B847F',
          fontSize: '21px',
          fontWeight: 'bold',
        }}
      >
        {current?.name}
      </div>

      <div style={{ paddingTop: 50, paddingBottom: 10, marginLeft: 20 }}>
        {current?.items.map((item, index) => (
          <>
            {item.isActive && (
              <Form.Item
                {...formItemLayout}
                name="radio_button_name"
                rules={[{ required: true, message: requiredMessage }]}
                key={item.id}
              >
                <Radio.Group tabIndex="0" className="radlo_button_assessment">
                  <EdbRadio
                    ref={index === 0 ? firstRadioButtonRef : null}
                    tabIndex="0"
                    key={item.id}
                    value={item.name}
                    name={item.id}
                    onClick={() => listClickLastButtonHandler(item.id)}
                    style={{ fontSize: '18px' }}
                  >
                    {item.name}
                  </EdbRadio>
                </Radio.Group>
              </Form.Item>
            )}
          </>
        ))}
      </div>

      <Row>
        <Col span={16}>{tooltipGrid}</Col>
      </Row>

      <Row>
        <Col span={16}>
          {/* Modal to display the tooltipGrid */}
          <Modal visible={isModalVisible} onCancel={handleCancel} onKeyDown={(e) => handleCancel(e)} footer={false}>
            {tooltipGrid}
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default FirstGrid;
