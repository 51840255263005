import React, { useState, useEffect } from 'react';
import { Card, Form } from 'antd';
import { NUMBERS_USER } from '../../../helpers/userapp';
import PersonSsn from '../PersonInfo/PersonSsn';
import PersonAddress from '../PersonInfo/PersonAddress';
import VerticalSpace from '../../Layout/VerticalSpace';
import DeathPerson from './DeathPerson';
import EdbCard from '../../Card';

export const GeneralInfoDeath = () => {
  const form = Form.useFormInstance();
  const [isEmailNull, setEmailNull] = useState(false);
  const [isPostIndexNull, setPostIndexNull] = useState(false);

  useEffect(() => {
    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      setEmailNull(true);
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      setPostIndexNull(true);
    }
  });

  return (
    <VerticalSpace>
      <EdbCard
        title="Դիմումատուի տվյալներ"
        style={{
          marginBottom: 10,
          borderTop: '1px solid #C0C1C2',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <PersonSsn title={'Դիմումատուի տվյալներ'} checkEmail={isEmailNull} />
      </EdbCard>
      <PersonAddress checkPostIndex={isPostIndexNull} />
      <EdbCard
        title="Մահացած անձի տվյալներ"
        style={{
          marginBottom: 10,
          border: 0,
        }}
        className="form__person_place"
      >
        <DeathPerson />
      </EdbCard>
    </VerticalSpace>
  );
};

export default GeneralInfoDeath;
