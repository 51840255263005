import React from 'react';
import { Col, Row } from 'antd';

import Logo from './Logo';
import SocServiceLogo from './SocServiceLogo';
import MainHeader from './MainHeader';
import Beta from './images/beta.png';
import VisionServices from '../VisionServices/VisionServices';

const DefaultHeader = () => {
  return (
    <MainHeader>
      <Row justify="space-between" align="middle">
        <Col>
          <Logo />
        </Col>
        <Col>
          <SocServiceLogo />
          <img src={Beta} style={{ width: '40px' }} alt="" />
          <VisionServices />
        </Col>
      </Row>
    </MainHeader>
  );
};

export default DefaultHeader;
