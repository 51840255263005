import React, { useEffect } from 'react';
import { Card, Form, Radio, Row, Col, Input } from 'antd';
import EdbRadio from '../../Radio';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formItemLayout, requiredMessage, providedList, NUMBERS_USER, COLORS } from '../../../helpers/userapp';
import OptionTitle from '../OptionTitle';
import { DICTIONARY_URL } from '../../../helpers/constants';
import EdbCheckbox from '../../Checkbox';
import DeathDocs from './DeathDocs';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
import EdbCard from '../../Card';

export const DeathPersonDocuments = ({ setAreFilesOk, subtypeId }) => {
  const form = Form.useFormInstance();
  const { data: dataNotificationType } = useGetDictionaryPublic(DICTIONARY_URL.GET_NOTIFICATION_TYPES);

  const arrayListValTitles = OptionTitle(true);

  const changeRadioNotice = (event) => {
    if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      if (form.getFieldValue('Representative_email') === null && form.getFieldValue('Person_email') === null) {
        form.resetFields(['email_check']);
      } else {
        form.setFieldsValue({
          email_check: 'true',
        });
      }
    } else {
      form.setFieldsValue({
        email_check: 'true',
      });
    }

    if (
      event.target.value === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      if (form.getFieldValue('postIndexAddress') === undefined) {
        form.resetFields(['post_index_check']);
      } else {
        form.setFieldsValue({
          post_index_check: 'true',
        });
      }
    } else {
      form.setFieldsValue({
        post_index_check: 'true',
      });
    }
  };

  const changeReceiveNotice = (event) => {
    if (
      event.target.value === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      if (form.getFieldValue('Representative_email') === null && form.getFieldValue('Person_email') === null) {
        form.resetFields(['email_check']);
      } else {
        form.setFieldsValue({
          email_check: 'true',
        });
      }
    } else {
      form.setFieldsValue({
        email_check: 'true',
      });
    }

    if (
      event.target.value === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      if (form.getFieldValue('postIndexAddress') === undefined) {
        form.resetFields(['post_index_check']);
      } else {
        form.setFieldsValue({
          post_index_check: 'true',
        });
      }
    } else {
      form.setFieldsValue({
        post_index_check: 'true',
      });
    }
  };

  useEffect(() => {
    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE
    ) {
      if (form.getFieldValue('Representative_email') === null && form.getFieldValue('Person_email') === null) {
        form.resetFields(['email_check']);
      } else {
        form.setFieldsValue({
          email_check: 'true',
        });
      }
    } else {
      form.setFieldsValue({
        email_check: 'true',
      });
    }

    if (
      form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETWO ||
      form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETWO
    ) {
      if (form.getFieldValue('postIndexAddress') !== undefined) {
        form.setFieldsValue({
          post_index_check: 'true',
        });
      } else {
        form.resetFields(['post_index_check']);
      }
    } else {
      form.setFieldsValue({
        post_index_check: 'true',
      });
    }
  }, [form]);

  return (
    <>
      <div key={1}>
        <EdbCard
          title="Կցվող փաստաթղթեր"
          style={{
            marginBottom: 10,
            borderTop: 0,
            borderBottom: '1px solid #C0C1C2',
            borderRight: 0,
            borderLeft: 0,
            padding: 0,
            width: '100%',
            overflow: 'auto',
          }}
          className="form__person_place"
        >
          <DeathDocs setAreFilesOk={setAreFilesOk} subtypeId={subtypeId} />

          <Form.Item name="file_item_true" rules={[{ required: true }]}>
            <Input type="hidden" value={true} />
          </Form.Item>
        </EdbCard>
        <p style={{ marginTop: '15px', color: COLORS.whiteGray }}>
          <ExclamationCircleOutlined /> Կցվող փաստաթուղթը պետք է լինի jpg/png/pdf ֆորմատի և չգերազանցի 10MB-ը։
        </p>
        <div style={{ marginTop: '15px', color: COLORS.whiteGray }}>
          {/* //! use Array.prototype.map() method */}
          <ExclamationCircleOutlined /> Անհրաժեշտ կցվող փաստաթղթերն են՝
          <ul>
            <li>
              ծառայության համապատասխան ստորաբաժանման կողմից ԽՍՀՄ զինված ուժերում զինվորական ծառայության ընթացքում
              հաշմանդամություն ձեռք բերած շարքային կազմի զինծառայողների, պաշտպանության նախարարության, արդարադատության
              նախարարության, արտակարգ իրավիճակների նախարարության կողմից իրենց համակարգերի զինծառայողների համար տրված
              ուղեգրերը, որտեղ նշվում է ուղեգրման նպատակը;
            </li>
            <li>մահվան վկայականը;</li>
            <li>մահվան մասին բժշկական վկայականը;</li>
            <li>
              տարած հիվանդությունների կամ կրած վնասվածքների փաստը հավաստող կամ ռազմաբժշկական փաստաթղթերից որևէ մեկը, կամ
              զինվորական կոմիսարիատի տված տեղեկանքը 1986 թվականի ապրիլի 26-ից մինչև 1987 թվականի դեկտեմբերի 31-ը
              Չեռնոբիլի ատոմային էլեկտրակայանի վթարի հետևանքների վերացման աշխատանքների կատարման ժամանակ զինվորական
              ծառայողական պարտականությունները կատարելու մասին կամ Հայաստանի Հանրապետության առողջապահության նախարարության
              «Ճառագայթային բժշկության և այրվածքների գիտական կենտրոն» փակ բաժնետիրական ընկերության (նախկինում` բժշկական
              ճառագայթաբանության գիտահետազոտական ինստիտուտ) տված փաստաթուղթը կամ միջգերատեսչական փորձաքննության խորհրդի
              եզրակացությունը հիվանդության պատճառական կապի մասին;
            </li>
            <li>բժշկական քարտից քաղվածք ( առկայության դեպքում);</li>
            <li>այլ (առկայության դեպքում):</li>
          </ul>
        </div>
      </div>

      <Row
        style={{
          gap: 10,
          borderBottom: '1px solid #C0C1C2',
        }}
      >
        <Col span={11}>
          <EdbCard
            title={arrayListValTitles[1]}
            style={{
              marginBottom: 10,
              border: 0,
            }}
            className="form__person_place"
          >
            <span id="receiveNoticeTitleOne" hidden>
              Ծանուցումը նախընտրում եմ ստանալ
            </span>
            <Form.Item {...formItemLayout} name="radio_notice" rules={[{ required: true, message: requiredMessage }]}>
              <Radio.Group
                className="radlo_button"
                name="one"
                role="radioGroup"
                aria-labelledby="receiveNoticeTitleOne"
                onChange={(e) => changeRadioNotice(e)}
              >
                {dataNotificationType.map((itemNotice) => (
                  <EdbRadio
                    key={itemNotice.id}
                    value={itemNotice.id}
                    name={itemNotice.id}
                    className="radlo_button"
                    disabled={!itemNotice.isActive}
                  >
                    {itemNotice.id === NUMBERS_USER.ITEMCASETHREE &&
                    form.getFieldValue('radio_notice') === NUMBERS_USER.ITEMCASETHREE &&
                    form.getFieldValue('email_check') === '' ? (
                      <span style={{ color: 'red' }}>{itemNotice.name}</span>
                    ) : (
                      <span>{itemNotice.name}</span>
                    )}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>
        </Col>
        <Col span={12}>
          <EdbCard
            title={arrayListValTitles[29]}
            style={{
              marginBottom: 10,
              border: 0,
            }}
            className="form__person_place"
          >
            <span id="receiveNoticeTitle" hidden>
              Որոշումը նախընտրում եմ ստանալ
            </span>
            <Form.Item {...formItemLayout} name="receive_notice" rules={[{ required: true, message: requiredMessage }]}>
              <Radio.Group
                className="radlo_button"
                name="two"
                role="radioGroup"
                aria-labelledby="receiveNoticeTitle"
                onChange={(e) => changeReceiveNotice(e)}
              >
                {dataNotificationType.map((itemNotice) => (
                  <EdbRadio
                    key={itemNotice.id}
                    value={itemNotice.id}
                    name={itemNotice.id}
                    className="radlo_button"
                    disabled={!itemNotice.isActive}
                  >
                    {itemNotice.id === NUMBERS_USER.ITEMCASETHREE &&
                    form.getFieldValue('receive_notice') === NUMBERS_USER.ITEMCASETHREE &&
                    form.getFieldValue('email_check') === '' ? (
                      <span style={{ color: 'red' }}>{itemNotice.name}</span>
                    ) : (
                      <span>{itemNotice.name}</span>
                    )}
                  </EdbRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </EdbCard>
        </Col>
        <Col span={12}>
          <Form.Item
            {...formItemLayout}
            name="email_check"
            rules={[
              {
                required: true,
                message: 'Խնդրում ենք նախորդ էջում լրացնել Էլեկտրոնային փոստի հասցե դաշտը',
                preserve: true,
              },
            ]}
          >
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="post_index_check"
            rules={[
              { required: true, message: 'Խնդրում ենք նախորդ էջում լրացնել փոստային դասիչ դաշտը', preserve: true },
            ]}
          >
            <Input type="hidden" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <EdbCard
            style={{
              marginBottom: '-15px',
              marginTop: 20,
              borderTop: 30,
              border: 0,
              padding: 0,
            }}
            className="form__person_place"
          >
            <Form.Item {...formItemLayout} name="provided_field" rules={[{ required: true, message: requiredMessage }]}>
              <EdbCheckbox.Group options={providedList} />
            </Form.Item>
          </EdbCard>
        </Col>
      </Row>
    </>
  );
};

export default DeathPersonDocuments;
