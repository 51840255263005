import React, { useState, useRef } from 'react';
import { Form, Input, DatePicker, Select, Col, Button, Alert, Modal } from 'antd';
import { formItemLayout, patternMessage, requiredMessage, uploadImgError } from '../../../helpers/userapp';
import { CloseOutlined, PaperClipOutlined, PlusSquareOutlined, EyeOutlined, CheckOutlined } from '@ant-design/icons';

import EdbSelect from '../../Select';
import { DICTIONARY_URL } from '../../../helpers/constants';
import axios from 'axios';
import useGetFiles from '../../../api/Dictionary/useGetFiles';
import useFileDelete from '../../../api/User/useFileDelete';
import moment from 'moment';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';

const { Option } = Select;

export const AddDeleteTableRows = ({ requiredType = 1, subtypeId }) => {
  const form = Form.useFormInstance();
  const { data: dataCauses } = useGetDictionaryPublic(DICTIONARY_URL.GET_CAUSES);
  const { data: documentList } = useGetDictionaryPublic(
    `${DICTIONARY_URL.GET_ATTACHED_DOCS}?evaluationSubtype=${subtypeId}`
  );
  const [getFileUrl, setrFileUrl] = useState(false);
  const [getFileType, setFileType] = useState(false);
  const [getImgPreview, setImgPreview] = useState('');
  const [getImgCheckError, setImgCheckError] = useState(false);
  const [getAddButtonDisabled, setAddButtonDisabled] = useState(true);
  const [getFirstDate, setFirstDate] = useState(false);
  const [documentType, setDocumentType] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const firstElementRef = useRef();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  useGetFiles(
    DICTIONARY_URL.GET_FILE_BY_NAME,
    {
      enabled: !!getFileUrl,
      onSuccess: (data) => {
        const objectURL = URL.createObjectURL(new Blob([data], { type: getFileType }));
        const projects = form.getFieldsValue('projects');

        const updatedProjects = projects.projects.map((project, key) => {
          if (key === getImgPreview) {
            return {
              ...project,
              file_item_show: objectURL,
              file_item_checksum: getFileUrl,
            };
          }

          return project;
        });
        projects.projects = updatedProjects;
        form.setFieldsValue({ ...projects });

        if (projects?.projects.length === 0) {
          setAddButtonDisabled(true);
          setFirstDate(false);
        }
      },
    },
    {
      fileChecksum: getFileUrl,
      contentType: getFileType,
    }
  );

  const handleChange = (e, fieldKey) => {
    const projects = form.getFieldsValue('projects');
    let boolProj = 0;
    if (projects.projects && projects.projects.length !== 0) {
      //! Check usage of reduce method
      projects.projects.reduce((projItem, projValue) => {
        if (projValue.doc_type_table === requiredType) {
          boolProj += 1;
        }
      }, []);
    }

    if (boolProj === 0) {
      form.setFieldsValue({
        file_item_true: '',
      });
    } else {
      form.setFieldsValue({
        file_item_true: true,
      });
    }

    e.preventDefault();
    setImgCheckError(false);
    const fileSize = e.target.files[0].size;
    if (fileSize < 10000000) {
      const fileFieldsArr = form.getFieldsValue();
      const fileFields = fileFieldsArr.projects.pop();
      const url = `${process.env.REACT_APP_SUBMIT_APPLICATION_URL + '/EDisability/UploadFiles'}`;
      const img = e.target.files[0];
      const formDataImg = new FormData();
      formDataImg.append('Ssn', form.getFieldValue('Person_ssn') === undefined ? '' : form.getFieldValue('Person_ssn'));
      formDataImg.append(
        'DocumentNumber',
        fileFields.for_the_doc_table === undefined ? '' : fileFields.for_the_doc_table
      );
      formDataImg.append(
        'DocumentName',
        fileFields.doc_type_name_input === undefined ? '' : fileFields.doc_type_name_input
      );
      formDataImg.append(
        'DisabilityCauseId',
        fileFields.doc_type_name_select === undefined ? '' : fileFields.doc_type_name_select
      );
      formDataImg.append(
        'OfficeName',
        fileFields.name_of_the_provider_table === undefined ? '' : fileFields.name_of_the_provider_table
      );
      formDataImg.append(
        'BirthDate',
        form.getFieldValue('Person_birthDate') === undefined
          ? ''
          : moment(form.getFieldValue('Person_birthDate')).format('YYYY-MM-DD')
      );
      formDataImg.append('Hash', '');
      formDataImg.append(
        'ValidUntil',
        fileFields.issued_date ? moment(fileFields.issued_date).format('YYYY-MM-DD') : ''
      );
      formDataImg.append(
        'IssuedDate',
        fileFields.dalivery_date === undefined ? '' : moment(fileFields.dalivery_date).format('YYYY-MM-DD')
      );
      formDataImg.append('AttachedDocumentTypeId', fileFields.doc_type_table);
      formDataImg.append('File', img);

      axios
        .post(url, formDataImg)
        .then((res) => {
          if (res && res.data.isSuccess === true) {
            setrFileUrl(res.data.fileChecksum);
            setFileType(img.type);
            setImgPreview(fieldKey);
            setAddButtonDisabled(true);
            setFirstDate(false);
          } else {
          }
        })
        .catch((error) => {
          if (error && (error.response.data.status === 400 || error.response.data.status === 500)) {
            setImgCheckError(uploadImgError);
          }
        });
    } else {
      setImgCheckError(uploadImgError);
    }
  };

  const onFileChange = (event) => {
    event.target.value = '';
  };

  const { mutate: mutateDeleteFile } = useFileDelete({
    onSuccess: (res) => {
      const projects = form.getFieldsValue('projects');
      if (projects.projects && projects.projects.length !== 0) {
        //! redundant usage of reduce method
        projects.projects.reduce((projItem, projValue) => {
          if (projValue.doc_type_table === 1) {
            form.setFieldsValue({
              file_item_true: true,
            });
          } else {
            form.setFieldsValue({
              file_item_true: '',
            });
          }
        }, []);
      }
      if (projects.projects.length === 0) {
        form.setFieldsValue({
          file_item_true: '',
        });
      }
      // console.log('');
    },
    onError: (resError) => {
      console.log('error');
    },
    enabled: false,
  });

  const handleDelete = (e, fieldKey) => {
    e.stopPropagation();
    const projects = form.getFieldsValue('projects');

    function getFieldValue(arr) {
      for (let i = 0; i <= arr.length; i++) {
        if (i === fieldKey) return arr[i];
      }
    }

    const fileItemChecksum = getFieldValue(projects.projects);
    const updatedProjects = projects.projects.filter((project, key) => {
      return fileItemChecksum.file_item_checksum !== project.file_item_checksum;
    });
    projects.projects = updatedProjects;
    form.setFieldsValue({ ...projects });

    mutateDeleteFile({
      checksum: fileItemChecksum.file_item_checksum,
      ssn: form.getFieldValue('Person_ssn'),
      birthDate: moment(form.getFieldValue('Person_birthDate')).format('YYYY-MM-DD'),
    });
  };

  const onChangeFirstDate = (e) => {
    var firstDate = e.format('YYYY-MM-DD');
    setFirstDate(firstDate);
  };

  const onChangeDocumentType = (type) => {
    setDocumentType(type);
  };

  const handleAfterClose = () => {
    // Focus the first element after the modal closes
    if (firstElementRef.current) {
      firstElementRef.current.focus();
    }
  };

  return (
    <Form.List name="projects" key={1}>
      {(projects, { add, remove }) => {
        return (
          <div key={1}>
            <Col span={24} style={{ overflowX: 'auto' }}>
              <table style={{ width: '100%', textAlign: 'center' }} cellPadding={20}>
                <thead style={{ background: '#0B847F', color: '#FFF' }}>
                  <tr>
                    <td style={{ borderRadius: '10px 0 0 0' }}>Փաստաթղթի տեսակ</td>
                    <td>Փաստաթղթի անվանում</td>
                    <td>Փաստաթղթի համար</td>
                    <td>Տրամադրող հաստատության անվանում</td>
                    <td>Տրամադրման ամսաթիվ</td>
                    <td>Վավեր է մինչև</td>
                    <td
                      style={{ borderRadius: '0 10px 0 0' }}
                      aria-label="Փաստաթուղթ կցելու համար հաջորդ քայլում հաստատեք հրամանը։"
                    >
                      {getAddButtonDisabled && getAddButtonDisabled === true && (
                        <Button
                          style={{ background: 'none', color: '#ffffff', border: 'none' }}
                          onClick={() => {
                            add(setAddButtonDisabled(false));
                            setFirstDate(false);
                            showModal();
                          }}
                        >
                          <PlusSquareOutlined
                            aria-label="Հաստատեք հրամանը այնուհետեվ պետք է լրացվի որոշ դաշտեր, որից հետո կցեք փաստաթուղթը"
                            style={{ fontSize: '20px' }}
                          />
                        </Button>
                      )}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((field, index) => (
                    <tr key={field.key}>
                      <td style={{ margin: 0, padding: 0 }}>
                        <Form.Item
                          {...formItemLayout}
                          name={[field.name, 'doc_type_table']}
                          rules={[{ required: true, message: requiredMessage }]}
                        >
                          <EdbSelect
                            placeholder="Ընտրել"
                            tabIndex="0"
                            ref={firstElementRef}
                            aria-label={
                              documentType
                                ? 'ընտրված փաստաթուղթ,' +
                                  documentList.find((region) => region.id === documentType)?.name
                                : 'Ընտրացանկից ընտրեք փաստաթղթի տեսակը, պարտադիր դաշտ'
                            }
                            style={{ width: '200px' }}
                            onChange={onChangeDocumentType}
                          >
                            {documentList.map((docListItem) => (
                              <Option key={docListItem.id} value={docListItem.id}>
                                {docListItem.name}
                              </Option>
                            ))}
                          </EdbSelect>
                        </Form.Item>
                      </td>
                      <td style={{ margin: 0, padding: 5 }}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues?.projects?.[field.name]?.doc_type_table !==
                            currentValues?.projects?.[field.name]?.doc_type_table
                          }
                        >
                          {({ getFieldValue }) => {
                            return (
                              <div key={2}>
                                {getFieldValue(['projects', field.name, 'doc_type_table']) === 2 ? (
                                  <Form.Item
                                    {...formItemLayout}
                                    name={[field.name, 'doc_type_name_select']}
                                    rules={[{ required: true, message: requiredMessage }]}
                                  >
                                    <EdbSelect
                                      placeholder="Ընտրել"
                                      tabIndex="0"
                                      aria-label="Ընտրացանկից ընտրեք փաստաթղթի անվանումը, պարտադիր դաշտ"
                                      style={{ width: '200px' }}
                                    >
                                      {dataCauses.map((docListCausesItem) => (
                                        <Option key={docListCausesItem.id} value={docListCausesItem.id}>
                                          {docListCausesItem.name}
                                        </Option>
                                      ))}
                                    </EdbSelect>
                                  </Form.Item>
                                ) : getFieldValue(['projects', field.name, 'doc_type_table']) === 1 ||
                                  getFieldValue(['projects', field.name, 'doc_type_table']) === 3 ? (
                                  <Form.Item
                                    {...formItemLayout}
                                    name={[field.name, 'doc_type_name_input']}
                                    rules={[
                                      { required: true, message: requiredMessage },
                                      {
                                        pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/,
                                        message: patternMessage,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Լրացնել"
                                      tabIndex="0"
                                      aria-label="Մուտքագրեք փաստաթղթի անվանումը, պարտադիր դաշտ"
                                      size="large"
                                      style={{
                                        borderRadius: 10,
                                        color: '#000',
                                        width: '200px',
                                      }}
                                    />
                                  </Form.Item>
                                ) : (
                                  ''
                                )}
                              </div>
                            );
                          }}
                        </Form.Item>
                      </td>
                      <td style={{ margin: 0, padding: 5 }}>
                        <Form.Item
                          {...formItemLayout}
                          name={[field.name, 'for_the_doc_table']}
                          rules={[
                            { required: true, message: requiredMessage },
                            // { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                          ]}
                        >
                          <Input
                            placeholder="Լրացնել"
                            tabIndex="0"
                            aria-label="Մուտքագրեք փաստաթղթի համարը, պարտադիր դաշտ"
                            size="large"
                            style={{ borderRadius: 10, color: '#000', width: '200px' }}
                          />
                        </Form.Item>
                      </td>
                      <td style={{ padding: 5, margin: 0 }}>
                        <Form.Item
                          {...formItemLayout}
                          name={[field.name, 'name_of_the_provider_table']}
                          rules={[
                            { required: true, message: requiredMessage },
                            { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                          ]}
                        >
                          <Input
                            placeholder="Լրացնել"
                            tabIndex="0"
                            aria-label="Մուտքագրեք փաստաթուղթը տրամադրող հաստատության անվանումը, պարտադիր դաշտ"
                            size="large"
                            style={{ borderRadius: 10, color: '#000', width: '200px' }}
                          />
                        </Form.Item>
                      </td>
                      <td style={{ padding: 5, margin: 0 }}>
                        <Form.Item
                          {...formItemLayout}
                          name={[field.name, 'dalivery_date']}
                          rules={[{ required: true, message: requiredMessage }]}
                        >
                          <DatePicker
                            onChange={onChangeFirstDate}
                            disabledDate={(current) => current.isAfter(moment())}
                            tabIndex="0"
                            aria-label="Մուտքագրեք փաստաթղթի տրամադրման ամսաթիվը հետեվյալ ֆորմատով՝ տարի գիծ, ամիս գիծ, օր, պարտադիր դաշտ"
                            style={{
                              borderRadius: 10,
                              color: '#000',
                              width: '200px',
                            }}
                            size="large"
                            placeholder=""
                          />
                        </Form.Item>
                      </td>

                      <td s>
                        <Form.Item {...formItemLayout} name={[field.name, 'issued_date']}>
                          <DatePicker
                            disabledDate={(current) => {
                              if (getFirstDate !== false) {
                                return moment().add(-1, 'days') >= current;
                                // return current && current < moment(getFirstDate, "YYYY-MM-DD");
                                // return current && current < moment();
                              } else {
                                return current;
                              }
                            }}
                            style={{
                              borderRadius: 10,
                              color: '#000',
                              width: '200px',
                            }}
                            tabIndex="0"
                            aria-label="Մուտքագրեք փաստաթղթի վավերականության ամսաթիվը հետեվյալ ֆորմատով՝ տարի գիծ, ամիս գիծ, օր, ոչ պարտադիր դաշտ"
                            size="large"
                            placeholder=""
                          />
                        </Form.Item>
                      </td>

                      <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Form.Item
                          {...formItemLayout}
                          name={[field.name, 'dalivery_date_hidden']}
                          style={{ visibility: 'hidden' }}
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues?.projects?.[field.name]?.file_item_show !==
                            currentValues?.projects?.[field.name]?.file_item_show
                          }
                        >
                          {({ getFieldValue }) => {
                            if (getFieldValue(['projects', field.name, 'file_item_show']) === undefined) {
                              return (
                                <Form.Item
                                  key={field.key}
                                  style={{ margin: 0 }}
                                  name={[field.name, 'file_item_upload']}
                                  rules={[{ required: true, message: requiredMessage }]}
                                >
                                  <Button
                                    id="filePickerButton"
                                    style={{ cursor: 'pointer', margin: 0, border: 'none' }}
                                    onClick={() => document.getElementById('filePicker').click()}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter' || e.key === ' ') {
                                        document.getElementById('filePicker').click();
                                      }
                                    }}
                                    aria-label="Կցել փաստաթուղթը"
                                  >
                                    <PaperClipOutlined style={{ color: '#000' }} />
                                  </Button>
                                  <Input
                                    id="filePicker"
                                    key={1}
                                    style={{
                                      visibility: 'hidden',
                                      width: 0,
                                      height: 0,
                                      padding: 0,
                                    }}
                                    type="file"
                                    onChange={(e) => handleChange(e, field.key)}
                                    onClick={onFileChange}
                                  />
                                </Form.Item>
                              );
                            } else {
                              return (
                                <>
                                  <CheckOutlined style={{ color: 'green' }} />
                                  <Alert
                                    type="success"
                                    message={
                                      <span style={{ fontWeight: 'bold' }}>Փաստաթուղթը հաջողությամբ կցված է</span>
                                    }
                                    showIcon
                                  />
                                </>
                              );
                            }
                          }}
                        </Form.Item>

                        <div
                          key={3}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Form.Item style={{ padding: '5px', marginBottom: 0 }} name={[field.name, 'file_item_show']}>
                            <Input type="hidden" />
                          </Form.Item>

                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues?.projects?.[field.name]?.file_item_show !==
                              currentValues?.projects?.[field.name]?.file_item_show
                            }
                          >
                            {({ getFieldValue }) => {
                              return (
                                <a
                                  href={getFieldValue(['projects', field.name, 'file_item_show'])}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <EyeOutlined
                                    style={{ color: '#000', marginRight: '7px' }}
                                    aria-label="Դիտել փաստաթուղթը"
                                  />
                                </a>
                              );
                            }}
                          </Form.Item>

                          <Form.Item name={[field.name, 'file_item_checksum']}>
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues?.projects?.[field.name]?.file_item_show !==
                              currentValues?.projects?.[field.name]?.file_item_show
                            }
                          >
                            {({ getFieldValue }) => {
                              if (getFieldValue(['projects', field.name, 'file_item_show']) !== undefined) {
                                return (
                                  <Button
                                    style={{ background: 'none', color: '#ffffff', border: 'none' }}
                                    onClick={(e) => handleDelete(e, field.key)}
                                    aria-label="չեղարկել փաստաթղթի վերաբերյալ լրացված տեղեկատվությունը"
                                  >
                                    <CloseOutlined style={{ color: '#000', cursor: 'pointer' }} />
                                  </Button>
                                );
                              } else {
                                return (
                                  <Button
                                    style={{ background: 'none', color: '#ffffff', border: 'none' }}
                                    onClick={() => {
                                      remove(field.name);
                                      setAddButtonDisabled(true);
                                      setFirstDate(false);
                                    }}
                                    aria-label="չեղարկել փաստաթղթի վերաբերյալ լրացված տեղեկատվությունը"
                                  >
                                    <CloseOutlined style={{ color: '#000', cursor: 'pointer' }} />
                                  </Button>
                                );
                              }
                            }}
                          </Form.Item>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal
                title="Ուշադրություն"
                visible={isModalVisible}
                onCancel={handleClose}
                afterClose={handleAfterClose}
                footer={false}
              >
                {subtypeId === 8 ? (
                  <p>
                    Բժշկական փաստաթղթի կցումը, որի հիման վրա պետք է փոփոխվի Ծառայությունների անհատական ծրագիրը (ԾԱԾ),
                    պարտադիր է: Կցվող փաստաթուղթը պետք է լինի jpg/png/pdf ֆորմատի և չգերազանցի 10MB-ը։
                  </p>
                ) : (
                  <p>
                    Առկայության դեպքում կարող եք կցել Պատճառական կապի վերաբերյալ տեղեկանքը։ Կցվող փաստաթուղթը պետք է
                    լինի՝ jpg, png, pdf ֆորմատի և չգերազանցի տաս մեգաբայթը
                  </p>
                )}
              </Modal>
            </Col>
            {getImgCheckError}
          </div>
        );
      }}
    </Form.List>
  );
};

export default AddDeleteTableRows;
