import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, Col, Radio, Input, Select, Checkbox } from 'antd';
import EdbSelect from '../../Select';
import EdbCheckbox from '../../Checkbox';
import EdbRadio from '../../Radio';
import {
  style,
  checkTrue,
  formItemLayout,
  selfCareDifficulties,
  houseHoldItem,
  movementDifficulties,
  bodyPosition,
  communicationDifficulties,
  useTransportList,
  smokeItemList,
  requiredMessage,
  patternMessage,
  NUMBERS_USER,
} from '../../../helpers/userapp';
import OptionTitle from '../OptionTitle';
import calculate_age from '../GeneralInfo/calculateAge';
import EdbCard from '../../Card';

const { Option } = Select;

export const AssessmentQuestionnaire = () => {
  const form = Form.useFormInstance();
  const [valueCareDifficulties, setValueCareDifficulties] = useState(selfCareDifficulties);
  const [valueHouseHold, setValueHouseHold] = useState(houseHoldItem);
  const [valueMovementDifficulties, setValueMovementDifficulties] = useState(movementDifficulties);
  const [valueBodyPosition, setValueBodyPosition] = useState(bodyPosition);
  const [valueCommunicationDifficulties, setValueCommunicationDifficulties] = useState(communicationDifficulties);
  const [valueUseTransport, setValueUseTransport] = useState(useTransportList);
  const [valueSmoke, setValueSmoke] = useState(smokeItemList);

  const [haveDificultiesId, setHaveDificulties] = useState();
  const [havingTroubleId, setHavingTrouble] = useState();
  const [havieMobilityId, setHavieMobility] = useState();
  const [bodyPositionId, setBodyPosition] = useState();
  const [communicationDifficultiesId, setCommunicationDifficulties] = useState();
  const [useTransportId, setUseTransport] = useState();
  const [isSmokeId, setIsSmokeId] = useState();
  const firstSelectRef = useRef();

  const onChangeHandle = (e) => {
    setHaveDificulties(e);
    form.setFieldsValue({
      self_care_difficulties_type: null,
    });

    let objectCareDifficultiesGet = valueCareDifficulties.map((itemCareDifficulties) => {
      itemCareDifficulties.optionClick = false;
      if (itemCareDifficulties.id === e) {
        itemCareDifficulties.optionClick = true;
      }
      return itemCareDifficulties;
    });
    setValueCareDifficulties(objectCareDifficultiesGet);
  };

  const onChangeHandleHousehold = (e) => {
    setHavingTrouble(e);
    form.setFieldsValue({
      house_hold_difficulties_type: null,
    });

    let objectHouseHoldGet = valueHouseHold.map((itemHouseHold) => {
      itemHouseHold.optionClick = false;
      if (itemHouseHold.id === e) {
        itemHouseHold.optionClick = true;
      }
      return itemHouseHold;
    });
    setValueHouseHold(objectHouseHoldGet);
  };

  const onChangeMovementDifficulties = (e) => {
    setHavieMobility(e);
    form.setFieldsValue({
      movement_difficulties_type: null,
    });

    let objectMovementDifficulties = valueMovementDifficulties.map((itemMovement) => {
      itemMovement.optionClick = false;
      if (itemMovement.id === e) {
        itemMovement.optionClick = true;
      }
      return itemMovement;
    });
    setValueMovementDifficulties(objectMovementDifficulties);
  };

  const onChangeBodyPosition = (e) => {
    setBodyPosition(e);
    form.setFieldsValue({
      body_position_type: null,
    });

    let objectBodyPositionGet = valueBodyPosition.map((itemBodyPosition) => {
      itemBodyPosition.optionClick = false;
      if (itemBodyPosition.id === e) {
        itemBodyPosition.optionClick = true;
      }
      return itemBodyPosition;
    });

    setValueBodyPosition(objectBodyPositionGet);
  };

  const onChangeCommunicationDifficulties = (e) => {
    setCommunicationDifficulties(e);
    form.setFieldsValue({
      communication_difficulties_type: null,
    });

    let objectCommunicationDifficultiesGet = valueCommunicationDifficulties.map((itemCommunicationDifficulties) => {
      itemCommunicationDifficulties.optionClick = false;
      if (itemCommunicationDifficulties.id === e) {
        itemCommunicationDifficulties.optionClick = true;
      }
      return itemCommunicationDifficulties;
    });

    setValueCommunicationDifficulties(objectCommunicationDifficultiesGet);
  };

  const onChangeUseTransport = (e) => {
    setUseTransport(e);
    form.setFieldsValue({
      use_ransport_type: null,
    });

    let objectUseTransportGet = valueUseTransport.map((itemUseTransport) => {
      itemUseTransport.optionClick = false;
      if (itemUseTransport.id === e) {
        itemUseTransport.optionClick = true;
      }
      return itemUseTransport;
    });

    setValueUseTransport(objectUseTransportGet);
  };

  const onChangeSmoke = (e) => {
    setIsSmokeId(e);
    form.setFieldsValue({
      use_smoke_year: null,
      use_smoke_age_begin: null,
      use_smoke_radio: null,
    });
    let objectSmoke = valueSmoke.map((itemSmoke) => {
      itemSmoke.optionClick = false;
      if (itemSmoke.id === e) {
        itemSmoke.optionClick = true;
      }
      return itemSmoke;
    });

    setValueSmoke(objectSmoke);
  };

  useEffect(() => {
    if (firstSelectRef.current) {
      firstSelectRef.current.focus();
    }
  }, []);

  const arrayListValTitles = OptionTitle(true);

  return (
    <>
      <EdbCard
        title={arrayListValTitles[5]}
        style={{
          marginBottom: 10,
          borderTop: '1px solid #C0C1C2',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col span={7} xs={22} sm={16} md={9} lg={7}>
          <Form.Item
            {...formItemLayout}
            name="self_care_difficulties"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                haveDificultiesId
                  ? 'ունե՞ք ինքնասպասարկման դժվարություններ, ընտրված տարբերակ,' +
                    valueCareDifficulties.find((item) => item.id === haveDificultiesId)?.label
                  : 'Ընտրացանկից ընտրեք, ունե՞ք ինքնասպասարկման դժվարություններ'
              }
              onChange={(e) => onChangeHandle(e)}
              ref={firstSelectRef}
            >
              {valueCareDifficulties.map((valCareDiff) => (
                <Option key={valCareDiff.value} value={valCareDiff.value}>
                  {valCareDiff.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        <Col span={10} style={{ marginTop: '20px', marginLeft: '30px' }}>
          {valueCareDifficulties.map(
            (valCareDiff) =>
              valCareDiff.optionClick &&
              valCareDiff.items.length > 0 && (
                <Form.Item
                  {...formItemLayout}
                  key={1}
                  name="self_care_difficulties_type"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <EdbCheckbox.Group
                    role="list"
                    aria-label="Ինչպիսի՞ ինքնասպասարկման դժվարություններ ունեք"
                    aria-labelledby="care-difficulties-label"
                  >
                    {valCareDiff.items.map((item) => (
                      <Checkbox key={item.id} value={item.id}>
                        {item.label}
                      </Checkbox>
                    ))}
                  </EdbCheckbox.Group>
                </Form.Item>
              )
          )}
        </Col>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[6]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col span={7} xs={22} sm={16} md={9} lg={7}>
          <Form.Item
            {...formItemLayout}
            name="house_hold_difficulties"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                havingTroubleId
                  ? 'ունե՞ք դժվարություններ կենցաղային գործերում, ընտրված տարբերակ,' +
                    valueHouseHold.find((item) => item.id === havingTroubleId)?.label
                  : 'Ընտրացանկից ընտրեք, ունե՞ք դժվարություններ կենցաղային գործերում'
              }
              onChange={(e) => onChangeHandleHousehold(e)}
            >
              {valueHouseHold.map((valHouseHold) => (
                <Option key={valHouseHold.value} value={valHouseHold.value}>
                  {valHouseHold.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        <Col span={10} style={{ marginTop: '20px', marginLeft: '30px' }}>
          {valueHouseHold.map(
            (valHouseHold) =>
              valHouseHold.optionClick &&
              valHouseHold.items.length > 0 && (
                <Form.Item
                  key={2}
                  {...formItemLayout}
                  name="house_hold_difficulties_type"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <EdbCheckbox.Group
                    role="list"
                    aria-label="Ինչպիսի՞ դժվարություններ ունեք կենցաղային գործերում"
                    aria-labelledby="household-difficulties-label"
                  >
                    {valHouseHold.items.map((item) => (
                      <Checkbox key={item.id} value={item.id}>
                        {item.label}
                      </Checkbox>
                    ))}
                  </EdbCheckbox.Group>
                </Form.Item>
              )
          )}
        </Col>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[7]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Col span={7} xs={22} sm={16} md={9} lg={7}>
          <Form.Item
            {...formItemLayout}
            name="movement_difficulties"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                havieMobilityId
                  ? 'ունե՞ք տեղաշարժման դժվարություններ, ընտրված տարբերակ,' +
                    valueMovementDifficulties.find((item) => item.id === havieMobilityId)?.label
                  : 'Ընտրացանկից ընտրեք, ունե՞ք տեղաշարժման դժվարություններ'
              }
              onChange={(e) => onChangeMovementDifficulties(e)}
            >
              {valueMovementDifficulties.map((movementDifficultiesItem) => (
                <Option key={movementDifficultiesItem.value} value={movementDifficultiesItem.value}>
                  {movementDifficultiesItem.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        <Col span={10} style={{ marginTop: '20px', marginLeft: '30px' }}>
          {valueMovementDifficulties.map(
            (movementDifficultiesItem) =>
              movementDifficultiesItem.optionClick &&
              movementDifficultiesItem.items.length > 0 && (
                <Form.Item
                  key={3}
                  {...formItemLayout}
                  name="movement_difficulties_type"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <EdbCheckbox.Group
                    role="list"
                    aria-label="ինչպիսի՞ տեղաշարժման դժվարություններ ունեք"
                    aria-labelledby="movement-difficulties-label"
                  >
                    {movementDifficultiesItem.items.map((item) => (
                      <Checkbox key={item.id} value={item.id}>
                        {item.label}
                      </Checkbox>
                    ))}
                  </EdbCheckbox.Group>
                </Form.Item>
              )
          )}
        </Col>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[8]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Col span={7} xs={22} sm={16} md={9} lg={7}>
          <Form.Item {...formItemLayout} name="body_position" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                bodyPositionId
                  ? 'ունե՞ք դժվարություններ մարմնի դիրք փոխելիս, ընտրված տարբերակ,' +
                    valueBodyPosition.find((item) => item.id === bodyPositionId)?.label
                  : 'Ընտրացանկից ընտրեք, ունե՞ք դժվարություններ մարմնի դիրք փոխելիս'
              }
              onChange={(e) => onChangeBodyPosition(e)}
            >
              {valueBodyPosition.map((bodyPositionItem) => (
                <Option key={bodyPositionItem.value} value={bodyPositionItem.value}>
                  {bodyPositionItem.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        <Col span={10} style={{ marginTop: '20px', marginLeft: '30px' }}>
          {valueBodyPosition.map(
            (bodyPositionItem) =>
              bodyPositionItem.optionClick &&
              bodyPositionItem.items.length > 0 && (
                <Form.Item
                  key={4}
                  {...formItemLayout}
                  name="body_position_type"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <EdbCheckbox.Group
                    role="list"
                    aria-label="ինչպիսի՞ դժվարություններ ունեք մարմնի դիրք փոխելիս"
                    aria-labelledby="body-position-label"
                  >
                    {bodyPositionItem.items.map((item) => (
                      <Checkbox key={item.id} value={item.id}>
                        {item.label}
                      </Checkbox>
                    ))}
                  </EdbCheckbox.Group>
                </Form.Item>
              )
          )}
        </Col>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[9]}
        id="hands-difficulty-label"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Form.Item
          {...formItemLayout}
          name="difficulty_using_hands"
          rules={[{ required: true, message: requiredMessage }]}
        >
          <EdbRadio.Group aria-labelledby="hands-difficulty-label" role="radioGroup" name="hands-difficulty">
            {checkTrue.data.map((itemChoose) => (
              <Radio key={itemChoose.id} value={itemChoose.value} name={itemChoose.name} className="radlo_button">
                {itemChoose.label}
              </Radio>
            ))}
          </EdbRadio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[10]}
        id="communication-difficulties-label"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Form.Item
          {...formItemLayout}
          name="use_communication_difficulties"
          rules={[{ required: true, message: requiredMessage }]}
        >
          <EdbRadio.Group
            aria-labelledby="communication-difficulties-label"
            role="radioGroup"
            name="communication-difficulties"
          >
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio key={itemChoose.id} value={itemChoose.value}>
                {itemChoose.label}
              </EdbRadio>
            ))}
          </EdbRadio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[11]}
        id="hearing-difficulties-label"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Form.Item
          {...formItemLayout}
          name="use_hearing_difficulties"
          rules={[{ required: true, message: requiredMessage }]}
        >
          <Radio.Group
            className="radlo_button_assessment"
            role="radioGroup"
            aria-labelledby="hearing-difficulties-label"
            name="hearing-difficulties"
          >
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio key={itemChoose.id} value={itemChoose.value} name={itemChoose.name} className="radlo_button">
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[12]}
        id="use_seeing_difficulty"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Form.Item
          {...formItemLayout}
          name="use_seeing_difficulty"
          rules={[{ required: true, message: requiredMessage }]}
        >
          <Radio.Group
            className="radlo_button_assessment"
            role="radioGroup"
            aria-labelledby="use_seeing_difficulty"
            name="seeing-difficulty"
          >
            {checkTrue.data.map((itemChoose) => (
              <EdbRadio key={itemChoose.id} value={itemChoose.value} name={itemChoose.name} className="radlo_button">
                {itemChoose.label}
              </EdbRadio>
            ))}
          </Radio.Group>
        </Form.Item>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[13]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Col span={7} xs={22} sm={16} md={9} lg={7}>
          <Form.Item
            {...formItemLayout}
            name="communication_difficulties"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                communicationDifficultiesId
                  ? 'ունե՞ք մարդկանց հետ շփվելու դժվարություններ, ընտրված տարբերակ,' +
                    valueCommunicationDifficulties.find((item) => item.id === communicationDifficultiesId)?.label
                  : 'Ընտրացանկից ընտրեք, ունե՞ք մարդկանց հետ շփվելու դժվարություններ'
              }
              onChange={(e) => onChangeCommunicationDifficulties(e)}
            >
              {valueCommunicationDifficulties.map((communicationDifficultiesItem) => (
                <Option key={communicationDifficultiesItem.value} value={communicationDifficultiesItem.value}>
                  {communicationDifficultiesItem.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        <Col span={10} style={{ marginTop: '20px', marginLeft: '30px' }}>
          {valueCommunicationDifficulties.map(
            (communicationDifficultiesItem) =>
              communicationDifficultiesItem.optionClick &&
              communicationDifficultiesItem.items.length > 0 && (
                <Form.Item
                  key={5}
                  {...formItemLayout}
                  name="communication_difficulties_type"
                  rules={[{ required: true, message: requiredMessage }]}
                  aria-label="Ռադիոսեղմակների ցանկից ընտրեք, ու՞մ հետ շփվելիս ունեք դժվարություններ"
                >
                  <EdbCheckbox.Group
                    role="list"
                    aria-label="ու՞մ հետ շփվելիս ունեք դժվարություններ"
                    aria-labelledby="communication-position-label"
                  >
                    {communicationDifficultiesItem.items.map((item) => (
                      <Checkbox key={item.id} value={item.id}>
                        {item.label}
                      </Checkbox>
                    ))}
                  </EdbCheckbox.Group>
                </Form.Item>
              )
          )}
        </Col>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[14]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Col xs={22} sm={16} md={9} lg={7}>
          <Form.Item {...formItemLayout} name="use_transport" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                useTransportId
                  ? 'ունե՞ք դժվարություններ տրանսպորտից օգտվելիս, ընտրված տարբերակ,' +
                    valueUseTransport.find((item) => item.id === useTransportId)?.label
                  : 'Ընտրացանկից ընտրեք, ունե՞ք դժվարություններ տրանսպորտից օգտվելիս'
              }
              onChange={(e) => onChangeUseTransport(e)}
            >
              {valueUseTransport.map((useTransportItem) => (
                <Option key={useTransportItem.value} value={useTransportItem.value}>
                  {useTransportItem.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        <Col span={10} style={{ marginTop: '20px', marginLeft: '30px' }}>
          {valueUseTransport.map(
            (useTransportItem) =>
              useTransportItem.optionClick &&
              useTransportItem.items.length > 0 && (
                <Form.Item
                  key={6}
                  {...formItemLayout}
                  name="use_transport_type"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <EdbCheckbox.Group
                    role="list"
                    aria-label="ինպիսի՞ դժվարություններ ունեք տրանսպորտից օգտվելիս"
                    aria-labelledby="communication-position-label"
                  >
                    {useTransportItem.items &&
                      useTransportItem.items.map((valueTransportItem) => (
                        <Checkbox key={valueTransportItem.id} value={valueTransportItem.id}>
                          {form.getFieldValue('filling_application') === 1
                            ? valueTransportItem.label1
                            : valueTransportItem.label2}
                        </Checkbox>
                      ))}
                  </EdbCheckbox.Group>
                </Form.Item>
              )
          )}
        </Col>
      </EdbCard>

      <EdbCard
        title="Նկարագրություն"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_place"
      >
        <Row style={{ gap: 10 }}>
          <Col xs={12} sm={10} md={7} lg={3}>
            <Form.Item
              {...formItemLayout}
              label="Հասակը"
              name="use_height"
              rules={[
                { required: true, message: requiredMessage },
                { pattern: /^[0-9]*$/, message: patternMessage },
              ]}
            >
              <Input placeholder="սմ" aria-label="Մուտքագրեք ձեր հասակը" size="large" style={style} />
            </Form.Item>
          </Col>
          <Col xs={12} sm={10} md={7} lg={3}>
            <Form.Item
              {...formItemLayout}
              label="Քաշը"
              name="use_weight"
              rules={[
                { required: true, message: requiredMessage },
                { pattern: /^[0-9.,]*$/, message: patternMessage },
              ]}
            >
              <Input placeholder="կգ" aria-label="Մուտքագրեք ձեր քաշը" size="large" style={style} />
            </Form.Item>
          </Col>
        </Row>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[15]}
        style={{
          marginBottom: 10,
          border: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_smoke"
      >
        <Col xs={22} sm={16} md={9} lg={7}>
          <Form.Item {...formItemLayout} name="use_smoke" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                isSmokeId
                  ? 'ծխու՞մ եք, ընտրված տարբերակ,' + valueSmoke.find((item) => item.id === isSmokeId)?.label
                  : 'Ընտրացանկից ընտրեք ծխու՞մ եք'
              }
              onChange={(e) => onChangeSmoke(e)}
            >
              {valueSmoke.map((useSmokeItem) => (
                <Option key={useSmokeItem.value} value={useSmokeItem.value}>
                  {useSmokeItem.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        <Row style={{ gap: 10, marginTop: '20px' }}>
          {valueSmoke.map((useSmokeItem) =>
            useSmokeItem.optionClick && useSmokeItem.id === 1 ? (
              <Row key={7} style={{ gap: 10 }}>
                <Col key={8} xs={22} sm={16} md={9} lg={7}>
                  <Form.Item
                    key={9}
                    {...formItemLayout}
                    label="քանի տարի"
                    name="use_smoke_year"
                    rules={[
                      { required: true, message: requiredMessage },
                      { pattern: /^[0-9]*$/, message: patternMessage },
                    ]}
                  >
                    <Input
                      // placeholder="20 տարի"
                      aria-label="Մուտքագրեք թե քանի՞ տարի եք ծխում"
                      size="large"
                      style={style}
                    />
                  </Form.Item>
                </Col>
                <Col key={10} xs={22} sm={16} md={9} lg={7}>
                  <Form.Item
                    key={11}
                    {...formItemLayout}
                    label="ծխել սկսելու տարիքը"
                    name="use_smoke_age_begin"
                    rules={[
                      { required: true, message: requiredMessage },
                      { pattern: /^[0-9]*$/, message: patternMessage },
                    ]}
                  >
                    <Input
                      // placeholder="56 տարեկան"
                      aria-label="Մուտքագրեք ծխել սկսելու տարիքը"
                      size="large"
                      style={style}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : useSmokeItem.optionClick && useSmokeItem.id === 2 ? (
              <Col key={12} xs={22} sm={16} md={9} lg={7}>
                <Form.Item
                  key={13}
                  {...formItemLayout}
                  name="use_smoke_radio"
                  rules={[{ required: true, message: requiredMessage }]}
                  aria-label="Ռադիոսեղմակներից վերեվ և ներքեվ սեղմակների օգնությամբ ընտրեք, առհասարակ ծխե՞լ եք թե ոչ"
                >
                  <Radio.Group className="radlo_button_smoke">
                    {useSmokeItem.items.map((smokeChooseItem) => {
                      if (
                        calculate_age(form.getFieldValue('Person_birthDate')) >= 16 &&
                        smokeChooseItem.IsChild === false
                      ) {
                        return (
                          <EdbRadio
                            key={smokeChooseItem.id}
                            value={smokeChooseItem.value}
                            name={smokeChooseItem.name}
                            className="radlo_button"
                          >
                            {form.getFieldValue('filling_application') === NUMBERS_USER.ITEMCASEONE
                              ? smokeChooseItem.label1
                              : smokeChooseItem.label2}
                          </EdbRadio>
                        );
                      } else if (calculate_age(form.getFieldValue('Person_birthDate')) < 16) {
                        return (
                          <EdbRadio
                            key={smokeChooseItem.id}
                            value={smokeChooseItem.value}
                            name={smokeChooseItem.name}
                            className="radlo_button"
                          >
                            {form.getFieldValue('filling_application') === NUMBERS_USER.ITEMCASEONE
                              ? smokeChooseItem.label1
                              : smokeChooseItem.label2}
                          </EdbRadio>
                        );
                      }
                    })}
                  </Radio.Group>
                </Form.Item>
              </Col>
            ) : (
              ''
            )
          )}
        </Row>
      </EdbCard>
    </>
  );
};

export default AssessmentQuestionnaire;
