import React, { useReducer, useState, useEffect, useRef } from 'react';
import { Row, Form, Col, Radio, Input, Select } from 'antd';
import EdbSelect from '../../Select';
import EdbCheckbox from '../../Checkbox';
import EdbRadio from '../../Radio';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  requiredMessage,
  familyStatus,
  livingItems,
  familyComposition,
  caregiverCase,
  formItemLayout,
  patternMessage,
  checkTrue,
  haveTrue,
  NUMBERS_USER,
} from '../../../helpers/userapp';
import OptionTitle from '../OptionTitle';
import inforeducer from './inforeducer';
import useGetDictionaryPublic from '../../../api/Dictionary/useGetDictonaryPublic';
import { DICTIONARY_URL } from '../../../helpers/constants';
import calculate_age from '../GeneralInfo/calculateAge';
import EdbCard from '../../Card';

const { Option } = Select;

const GeneralInfoLast = () => {
  const form = Form.useFormInstance();
  const [valueCaregiver, setValueCaregiver] = useState(caregiverCase);
  const [getLivingOther, setLivingOther] = useState(false);
  const [getChildren, setChildren] = useState(false);
  const [getProffession, setProffession] = useState(false);
  const [familyStatusId, setFamilyStatus] = useState();
  const [livingWithId, setLivingWith] = useState();
  const [state, dispatch] = useReducer(inforeducer, []);
  const { data: educationItemsPublic } = useGetDictionaryPublic(DICTIONARY_URL.GET_ALL_EDUCATIONS);
  const { data: occupationsItemsPublic } = useGetDictionaryPublic(DICTIONARY_URL.GET_ALL_OCCUPATIONS);
  const firstSelectRef = useRef();

  const [haveChildId, setHaveChild] = useState();
  const [carePerson, setCarePerson] = useState();
  const [educationPlace, setEducationPlace] = useState();
  const [haveEducationId, setHaveEducationId] = useState();
  const [employmentTypeId, setEmploymentTypeId] = useState();

  const changeProffessionType = (event) => {
    setEmploymentTypeId(event);
    dispatch({ id: event });
  };

  useEffect(() => {
    if (firstSelectRef.current) {
      firstSelectRef.current.focus();
    }
  }, []);

  const style = { borderRadius: 10 };

  const onChangeHandle = (e) => {
    setCarePerson(e);

    form.setFieldsValue({
      caring_person: null, // Reset only if changing the person
    });

    const updatedCaregiver = valueCaregiver.map((caregiverCaseItem) => {
      return {
        ...caregiverCaseItem,
        optionClick: caregiverCaseItem.id === e, // Set the selected option
      };
    });

    setValueCaregiver(updatedCaregiver);

    // If the selected option has items, reset caring_person field to ensure validation
    const selectedCaregiver = updatedCaregiver.find((caregiver) => caregiver.id === e);
    console.log(selectedCaregiver);
    if (selectedCaregiver && selectedCaregiver.items.length > 0) {
      form.setFieldsValue({
        caring_person_check: selectedCaregiver.items[0].value, // Set default caring person if available
      });
    }
  };

  const changeLivingItems = (event) => {
    setLivingWith(event);
    form.setFieldsValue({
      live_item_input: null,
    });
    if (event === 9) {
      setLivingOther(true);
    } else {
      setLivingOther(false);
    }
  };

  const changeChildren = (event) => {
    setHaveChild(event);
    if (event === NUMBERS_USER.ITEMCASEONE) {
      setChildren(true);
    } else {
      setChildren(false);
    }
  };

  const changeProffession = (event) => {
    setHaveEducationId(event);
    if (event === NUMBERS_USER.ITEMCASEONE) {
      setProffession(true);
    } else {
      setProffession(false);
    }
  };

  const arrayListValTitles = OptionTitle(true);

  console.log(haveTrue.data);

  return (
    <>
      <EdbCard
        title="Ընտանեկան կարգավիճակը"
        style={{
          marginBottom: 10,
          borderTop: '1px solid #C0C1C2',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col sm={12} xs={22} md={12} lg={7}>
          <Form.Item {...formItemLayout} name="family_status" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              placeholder="Ընտրել"
              onChange={(status) => setFamilyStatus(status)}
              ref={firstSelectRef}
              aria-label={
                familyStatusId
                  ? 'ընտրված կարգավիճակը,' + familyStatus.find((status) => status.id === familyStatusId)?.label1
                  : 'Ընտրացանկից ընտրել ընտանեկան կարգավիճակը'
              }
            >
              {familyStatus &&
                familyStatus.map((valueFamilyItems) => {
                  if (
                    calculate_age(form.getFieldValue('Person_birthDate')) >= 16 &&
                    valueFamilyItems.IsChild === false
                  ) {
                    return (
                      <Option key={valueFamilyItems.id} value={valueFamilyItems.id}>
                        {form.getFieldValue('filling_application') === 1
                          ? valueFamilyItems.label1
                          : valueFamilyItems.label2}
                      </Option>
                    );
                  } else if (calculate_age(form.getFieldValue('Person_birthDate')) < 16) {
                    return (
                      <Option key={valueFamilyItems.id} value={valueFamilyItems.id}>
                        {form.getFieldValue('filling_application') === 1
                          ? valueFamilyItems.label1
                          : valueFamilyItems.label2}
                      </Option>
                    );
                  }
                })}
            </EdbSelect>
          </Form.Item>
        </Col>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[2]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col sm={12} xs={22} md={12} lg={7}>
          <Form.Item {...formItemLayout} name="live_item" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                livingWithId
                  ? 'ու՞մ հետ եք ապրում, ընտրված տարբերակ,' +
                    livingItems.find((status) => status.id === livingWithId)?.label1
                  : 'Ընտրացանկից ընտրել թե ու՞մ հետ եք ապրում'
              }
              onChange={(e) => changeLivingItems(e)}
            >
              {livingItems &&
                livingItems.map((valueLivingItems) => (
                  <Option key={valueLivingItems.id} value={valueLivingItems.id}>
                    {form.getFieldValue('filling_application') === 1
                      ? valueLivingItems.label1
                      : valueLivingItems.label2}
                  </Option>
                ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        {getLivingOther && (
          <Col span={8}>
            <Form.Item
              {...formItemLayout}
              name="live_item_input"
              rules={[
                { required: true, message: requiredMessage },
                { pattern: /^[ա-ֆԱ-Ֆ ]*$/, message: patternMessage },
              ]}
            >
              <Input placeholder="Լրացնել" size="large" style={style} />
            </Form.Item>
          </Col>
        )}
      </EdbCard>

      <EdbCard
        title="Ընտանիքի կազմը"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_family"
        aria-label="Նշեք ընտանիքի կազմը"
        tabIndex="0"
      >
        <Col xs={24} sm={12} md={12} lg={20}>
          <Form.Item
            {...formItemLayout}
            name="family_composition"
            rules={[{ required: true, message: requiredMessage }]}
            role="group"
          >
            <EdbCheckbox.Group options={familyComposition} />
          </Form.Item>
        </Col>
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[30]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
          padding: 0,
        }}
        className="form__person_family"
      >
        <Col sm={12} xs={22} md={12} lg={7}>
          <Form.Item {...formItemLayout} name="have_childrens" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              onChange={(e) => changeChildren(e)}
              placeholder="Ընտրել"
              aria-label={
                haveChildId
                  ? 'ունե՞ք անչափահաս երեխաներ, ընտրված է,' +
                    checkTrue.data.find((child) => child.value === haveChildId)?.label
                  : 'Ընտրացանկից ընտրեք, ունե՞ք անչափահաս երեխաներ'
              }
              options={checkTrue.data}
            />
          </Form.Item>
        </Col>

        {getChildren && (
          <Col span={8} sm={12} xs={22} md={12} lg={7}>
            <Form.Item
              {...formItemLayout}
              label="Անչափահաս երեխաների թիվը"
              name="count_children"
              rules={[
                { required: true, message: requiredMessage },
                { pattern: /^[1-9]*$/, message: patternMessage },
              ]}
            >
              <Input
                placeholder="Լրացնել"
                aria-label="Մուտքագրեք անչափահաս երեխաների թիվը"
                size="large"
                style={style}
              />
            </Form.Item>
          </Col>
        )}
      </EdbCard>

      <EdbCard
        title={arrayListValTitles[3]}
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col sm={12} xs={22} md={12} lg={7}>
          <Form.Item
            {...formItemLayout}
            name="caring_person_check"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                carePerson
                  ? 'ունե՞ք խնամող անձ, ընտրված է,' + valueCaregiver.find((person) => person.id === carePerson)?.label
                  : 'Ընտրացանկից ընտրեք, ունե՞ք խնամող անձ'
              }
              onChange={(e) => onChangeHandle(e)}
            >
              {valueCaregiver.map((evItemCargiver) => (
                <Option key={evItemCargiver.value} value={evItemCargiver.value}>
                  {evItemCargiver.label}
                </Option>
              ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        {valueCaregiver.map(
          (evItemCargiver) =>
            evItemCargiver.optionClick &&
            evItemCargiver.items.length > 0 && (
              <Col
                key={evItemCargiver.id}
                sm={12}
                xs={22}
                md={12}
                lg={7}
                style={{ marginTop: '20px', marginLeft: '30px' }}
              >
                <Form.Item
                  {...formItemLayout}
                  name="caring_person"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <span id="caringPerson" hidden>
                    Ընտրեք խնամող անձին կամ հաստատությունը
                  </span>
                  <Radio.Group
                    className="radlo_button"
                    aria-labelledby="caringPerson"
                    role="radioGroup"
                    name="caringPerson"
                    onChange={(e) =>
                      form.setFieldsValue({
                        caring_person: e.target.value, // Reset only if changing the person
                      })
                    }
                  >
                    {evItemCargiver.items.map((itemCaregiverType) => (
                      <EdbRadio
                        key={itemCaregiverType.id}
                        value={itemCaregiverType.value}
                        name={itemCaregiverType.name}
                      >
                        {itemCaregiverType.label}
                      </EdbRadio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            )
        )}
      </EdbCard>

      <EdbCard
        title="Կրթություն՝ նշել վերջին կրթական վայրը"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col sm={12} xs={22} md={12} lg={7}>
          <Form.Item
            {...formItemLayout}
            name="place_of_education"
            rules={[{ required: true, message: requiredMessage }]}
          >
            <EdbSelect
              placeholder="Ընտրել"
              onChange={(e) => setEducationPlace(e)}
              aria-label={
                educationPlace
                  ? 'վերջին կրթական վայրը, ընտրված է,' +
                    educationItemsPublic.find((item) => item.id === educationPlace)?.label1
                  : 'Ընտրացանկից ընտրեք, կրթություն՝ վերջին կրթական վայրը'
              }
            >
              {educationItemsPublic &&
                educationItemsPublic.map((valueEducationItems) => (
                  <Option key={valueEducationItems.id} value={valueEducationItems.id}>
                    {form.getFieldValue('filling_application') === 1
                      ? valueEducationItems.label1
                      : valueEducationItems.label2}
                  </Option>
                ))}
            </EdbSelect>
          </Form.Item>
        </Col>
      </EdbCard>

      <EdbCard
        title="Մասնագիտություն"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col sm={12} xs={22} md={12} lg={7}>
          <Form.Item {...formItemLayout} name="profession_type" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                haveEducationId
                  ? 'ունե՞ք մասնագիտություն թե ոչ, ընտրված է' +
                    haveTrue.data.find((item) => item.value === haveEducationId)?.label1
                  : 'Ընտրացանկից ընտրեք, ունե՞ք մասնագիտություն թե ոչ'
              }
              onChange={(e) => changeProffession(e)}
            >
              {haveTrue.data &&
                haveTrue.data.map((valueHaveItems) => (
                  <Option key={valueHaveItems.value} value={valueHaveItems.value}>
                    {form.getFieldValue('filling_application') === 1 ? valueHaveItems.label1 : valueHaveItems.label2}
                  </Option>
                ))}
            </EdbSelect>
          </Form.Item>
        </Col>

        {getProffession && (
          <Col sm={12} xs={22} md={12} lg={7}>
            <Form.Item
              {...formItemLayout}
              name="profession_type_input"
              rules={[
                { required: true, message: requiredMessage },
                { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
              ]}
            >
              <Input placeholder="Լրացնել" aria-label="Մուտքագրեք ձեր մասնագիտությունը" size="large" style={style} />
            </Form.Item>
          </Col>
        )}
      </EdbCard>

      <EdbCard
        title="Զբաղվածություն"
        style={{
          marginBottom: 10,
          borderTop: '0',
          borderBottom: '1px solid #C0C1C2',
          borderRight: 0,
          borderLeft: 0,
        }}
        className="form__person_place"
      >
        <Col sm={12} xs={22} md={12} lg={7}>
          <Form.Item {...formItemLayout} name="employment_type" rules={[{ required: true, message: requiredMessage }]}>
            <EdbSelect
              placeholder="Ընտրել"
              aria-label={
                employmentTypeId
                  ? 'զբաղվածություն, ընտրված է' +
                    occupationsItemsPublic.find((item) => item.id === employmentTypeId)?.label1
                  : 'Ընտրացանկից ընտրեք, զբաղվածություն'
              }
              onChange={(e) => changeProffessionType(e)}
            >
              {occupationsItemsPublic &&
                occupationsItemsPublic.map((valueEmploymentItems) => {
                  if (
                    calculate_age(form.getFieldValue('Person_birthDate')) >= 16 &&
                    valueEmploymentItems.isChild === false
                  ) {
                    return (
                      <Option key={valueEmploymentItems.id} value={valueEmploymentItems.id}>
                        {form.getFieldValue('filling_application') === 1
                          ? valueEmploymentItems.label1
                          : valueEmploymentItems.label2}
                      </Option>
                    );
                  } else if (calculate_age(form.getFieldValue('Person_birthDate')) < 16) {
                    return (
                      <Option key={valueEmploymentItems.id} value={valueEmploymentItems.id}>
                        {form.getFieldValue('filling_application') === 1
                          ? valueEmploymentItems.label1
                          : valueEmploymentItems.label2}
                      </Option>
                    );
                  }
                })}
            </EdbSelect>
          </Form.Item>
        </Col>
      </EdbCard>

      {state.getProffessionTypeVisible && state.getProffessionTypeVisible === true && (
        <EdbCard
          title={state.getProffessionType}
          style={{
            marginBottom: 10,
            border: 0,
          }}
          className="form__person_place"
        >
          <Row style={{ gap: 10 }}>
            <Col span={10}>
              <Form.Item
                {...formItemLayout}
                name="textarea_work"
                rules={[
                  { required: state.getProffessionTypeRequired, message: requiredMessage },
                  { pattern: /^[ա-ֆԱ-Ֆ\n ]*$/, message: patternMessage },
                ]}
              >
                <Input.TextArea
                  rows={5}
                  placeholder="Լրացնել "
                  aria-label={`Մուտքագրեք ${state.getProffessionType}`}
                  size="large"
                  autosize={{ minRows: 5, maxRows: 6 }}
                  style={style}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                {...formItemLayout}
                name="work_year"
                style={{ width: '50%', marginBottom: 0 }}
                rules={[
                  { required: state.getProffessionTypeRequired, message: requiredMessage },
                  { pattern: /^(?:\d*)$/, message: patternMessage },
                ]}
              >
                <Input
                  // placeholder="20 ՏԱՐԻ"
                  aria-label={`Մուտքագրեք աշխատած տարիների ընդհանուր թիվը`}
                  size="large"
                  style={style}
                />
              </Form.Item>
              <div style={{ paddingTop: '20px' }}>
                <InfoCircleOutlined
                  type="info-circle"
                  tooltip="Աշխատած տարիների ընդհանուր թիվը"
                  style={{ paddingRight: '8px' }}
                />
                Աշխատած տարիների ընդհանուր թիվը
              </div>
            </Col>
          </Row>
        </EdbCard>
      )}
    </>
  );
};

export default GeneralInfoLast;
